<template>
<div class="overview">
	<div class="mobile-hidden">
		<div class="row">
			<div class="col-md-12">
				<div class="page-title">
					<h1 style="height: 48px">
						<i class="fa fa-credit-card" aria-hidden="true"></i> Invoice {{ $route.params.id }}
						
						<div v-for="(invoice, index) in  invoices" style="float: right;" :key="index">
							<button @click="invoiceAction(invoice, 'P')" class="add btn btn-light mr-2" type="button">PDF</button>
							<button v-if="invoice.status_id == 1 && currentUser.role.id >= 3" @click="invoiceAction(invoice, 'AC')" class="add btn btn-primary mr-2" type="button">Send for approval</button>
							<button v-if="invoice.type == 'DEBIT' && (invoice.status_id == 2 || invoice.status_id == 3) && (currentUser.role.id == 1 || currentUser.role.id == 4)" @click="invoiceAction(invoice, 'AP')" class="add btn btn-success mr-2" type="button">Approve</button>
							
							<button v-if="invoice.type == 'DEBIT' && (invoice.status_id == 2 || invoice.status_id == 3) && (currentUser.role.id >= 3)" @click="invoiceAction(invoice, 'RE')" class="add btn btn-info mr-2" type="button">Resend</button>
							<button v-if="invoice.type == 'DEBIT' && invoice.status_id != 3 && (currentUser.role.id == 1 || currentUser.role.id == 4) && invoice.status_id != 4" @click="invoiceAction(invoice, 'D')" class="add btn btn-danger mr-2" type="button">Decline</button>
							<!--<button v-if="invoice.status_id == 3" @click="invoiceAction(invoice, 'VC')" class="add btn btn-warning mr-2" type="button" data-toggle="modal" data-target="#myModal">Comments</button>-->
							<button v-if="invoice.type == 'CREDIT' && (currentUser.rights.invoice['*'] || currentUser.rights.invoice.d) && invoice.status_id != 4" @click="invoiceAction(invoice, 'APC')" class="add btn btn-success mr-2" type="button">Approve Credit</button>
							<button v-if="(currentUser.rights.invoice['*'] || currentUser.rights.invoice.d) && (invoice.status_id == 2 || invoice.status_id == 3) && invoice.status_id != 4" @click="invoiceAction(invoice, 'C')" class="add btn btn-danger mr-2" type="button">Cancel</button>
							<button v-if="invoice.status_id == 4 && currentUser.role.id >= 3" @click="invoiceAction(invoice, 'REKLC')" class="add btn btn-info mr-2" type="button">Resend to {{ invoice.department }}</button>
							<button v-if="invoice.type == 'DEBIT' && invoice.is_credited == 0 && invoice.status_id == 4 && currentUser.role.id >= 3" @click="invoiceAction(invoice, 'createCreditInvoice')" class="add btn btn-warning mr-2" type="button">Create Credit Invoice</button>
							<router-link :to="'/page/app/invoices'">
								<button type="button" class="add btn btn-light mr-2">
									Back
								</button>
							</router-link>
						</div>
					</h1>
				</div>
			</div>
		</div>
	</div>
	<div>
		<table class="table table-striped">
			<thead>
				<tr>
					<th>Department</th>
					<th>Date</th>
					<th>Reference</th>
					<th>Currency</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody v-if="invoices">
				<tr v-for="invoice in invoices" :key="invoice">
					<td>{{ invoice.department }}</td>
					<td>{{ invoice.date }}</td>
					<td>{{ invoice.reference }}</td>
					<td>{{ invoice.currency }}</td>
					<td>{{ invoice.status }}</td>
				</tr>
			</tbody>
		</table>
	</div>

	<div class="row">
		<div class="col-md-8">
			<h2>Trips</h2>
			<table class="table table-striped">
				<thead>
					<tr>
						<th>#</th>
						<th>Date</th>
						<th>Direction</th>
						<th>Airport</th>
						<th>Hotel</th>
						<th>Price</th>
						<th>VAT</th>
					</tr>
				</thead>
				<tbody v-if="invoices[0] && invoices[0].trips">
					<template v-for="(trip, index) in invoices[0].trips" :key="index">
						<tr>
							<td>{{ index + 1 }}</td>
							<td>{{ trip.date }}</td>
							<td>{{ trip.direction }}</td>
							<td>{{ trip.iata_code }}</td>
							<td>{{ trip.ref }}</td>
							<td>{{ trip.price }}</td>
							<td>{{ trip.vat_percentage }}</td>
						</tr>
						<tr v-if="trip && trip.fees && trip.fees.length">
							<td>&nbsp;</td>
							<td colspan="5">
								<h3>Surcharges</h3>
								<table class="table table-striped">
									<thead>
										<tr>
											<th>#</th>
											<th>Name</th>
											<th>Reason</th>
											<th>Extra Trip</th>
											<th>Price</th>
											<th>VAT percentage</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(fee, index) in trip.fees" :key="index">
											<td>{{ index + 1 }}</td>
											<td>{{ fee.name }}</td>
											<td>{{ fee.ref }}</td>
											<td>{{ fee.extra_trip }}</td>
											<td>{{ fee.price }}</td>
											<td>{{ fee.vat_percentage }}%</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
			<h2>Total</h2>
			<table class="table table-striped" v-for="invoice in invoices" :key="invoice">
				<tbody>
					<tr>
						<th>Total Excl. VAT</th>
						<td>{{ parseFloat(invoice.price).toFixed(2) }}</td>
					</tr>
					<tr>
						<th>VAT</th>
						<td>{{ parseFloat(invoice.vat).toFixed(2) }}</td>
					</tr>
					<tr>
						<th>Total Incl. VAT</th>
						<td>{{ parseFloat(getTotalPrice(invoice)).toFixed(2)}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="col-md-4">
			<h2>Invoice Reference</h2>
			<div class="card">
				<div class="card-body">
					<div style="background: yellow;">
						<b>
						Please fill your own invoice reference <u>before</u> approval.
						<br />
						</b>
					</div>
					<div>
						<br />
						{{ referenceStatus }}
					</div>
					<div class="input-group" v-if="invoices && invoices[0] && invoices[0].status">
						<input type="text" v-model="invoiceReference" :disabled="invoices[0].status >= 4 || currentUser.role.id != 1" class="form-control">
						<div class="input-group-append">
							<button class="btn btn-primary" type="button" @click="addReference()" :disabled="invoices[0].status >= 4 || currentUser.role.id != 1">Save</button>
						</div>
					</div>
				</div>
			</div>

			<h2>Comments</h2>
			<div class="card" v-if="invoices[0]">
				<div class="card-body" v-if="invoices[0].comments">
					<div class="message-bubble-left" v-show="!invoices[0].comments.length">
						<span>No comments.</span>
					</div>
					<div>
						<div  v-for="comment in invoices[0].comments" :key="comment">
							<div :class="{ 'message-bubble-right': (currentUser.id ==  comment.user_id), 'message-bubble-left': (currentUser.id !==  comment.user_id) }">
								<p class="text-muted">{{ comment.created_at }} - <b>{{ comment.user }}</b></p>
								<span v-html="comment.comment"></span>
							</div>
						</div>
					</div>
				</div>
				<div class="panel-footer" v-show="invoices[0].status_id == 3">
					<div class="input-group">
						<input type="text" v-model="comment" class="form-control">
						<div class="input-group-append">
							<button class="btn btn-primary" type="button" @click="addComment()">Send</button>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>

</template>
<script>
import { mapGetters } from 'vuex';
export default {
	data () {
		return {
			comment: '',
			invoices: [],
			loadingInvoices: false,
			invoiceReference: '',
			referenceStatus: '',
		}
	},
	methods:{
		/*
			Always copy from invoices/index.vue
		*/
		loadInvoices: function(id, year, month) {
			this.loadingInvoices = true;
			let url = '/invoice/getInvoice' + (id !== undefined && id ? '/' + id : '');
			
			if (year !== undefined && month !== undefined) {
				url = '/invoice/getInvoices/' + year + '/' + month; 
			}
			
			//get data from backend
			this.req('GET', url).then((response) => {
				if (response.status) {
					this.invoices = response.data;
					if(this.invoices[0].reference){
						this.invoiceReference = this.invoices[0].reference;
					}
				} else {
					this.invoices = [];
				}
				this.loadingInvoices = false;
			});
		},
		getTotalPrice: function(invoice) {
			return parseFloat(invoice.price) + parseFloat(invoice.vat);
		},
		addComment: function() {
			// verify invoice ID
			if (this.$route.params.id && this.comment) {
				//get data from backend
				this.req('POST', '/invoice_comment/insert/', {
					'invoice_id': this.$route.params.id,
					'supplier_id': (this.invoices[0].supplier_id ? this.invoices[0].supplier_id : 0),
					'comment': this.comment,
				}).then((response) => {
					if (response.status) {
						this.loadInvoices(this.$route.params.id);
					}
				});
				
				this.comment = '';
			}
		},
		addReference: function() {
			if (this.invoiceReference) {
				//get data from backend
				this.req('POST', '/invoice/addReference', {
					'invoice_id': this.$route.params.id,
					'reference': this.invoiceReference,
				}).then((response) => {
					if (response.status) {
						this.loadInvoices(this.$route.params.id);
						this.referenceStatus = "Reference updated.";
					} else {
						this.referenceStatus = "ERROR: Reference not updated.";
					}
				});
			}
		},
		updateInvoice: function(invoice) {
			this.req('GET', '/invoice/getInvoice/' + invoice.id).then((response) => {
				let index = this.invoices.indexOf(invoice);
				if (index !== -1) {
					this.invoices[index] = response.data[0];
				}
			});
		},
		/*
			Always copy from invoices/index.vue
		*/
		invoiceAction: function(invoice, action) {
			switch (action) {
				case 'P':
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							window.open('/invoice/createPDF/' + invoice.id, '_blank');
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'AC': // accept draft
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							this.req('POST', '/invoice/update/' + invoice.id, {'status_id': 2 }).then((response) => {
								if (response.status) {
									this.updateInvoice(invoice);
								}else{
									let errorMessage = 'Invoice accept ERROR';
									
									this.$swal('Error', errorMessage, 'error');
								}
							});
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'AP': // approve invoice
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							this.req('POST', '/invoice/approve/' + invoice.id).then((response) => {
								if (response.status) {
									this.updateInvoice(invoice);
								}else{
									let errorMessage = 'Invoice approve ERROR, please contact us';
									this.$swal('Error', errorMessage, 'error');
								}
							});
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'APC': // approve credit invoice
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							this.req('POST', '/invoice/approveCredit/' + invoice.id).then((response) => {
								if (response.status) {
									this.updateInvoice(invoice);
								}else{
									let errorMessage = 'Invoice approve ERROR';
									
									this.$swal('Error', errorMessage, 'error');
								}
							});
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'RE': // resend invoice
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							this.req('POST', '/invoice/resendInvoice/' + invoice.id).then((response) => {
								if (response.status) {
									this.$swal('Success', 'Invoice resend success', 'success');
									this.updateInvoice(invoice);
								}else{
									let errorMessage = 'Invoice resend ERROR';
									
									this.$swal('Error', errorMessage, 'error');
								}
							});
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'REKLC': // resend invoice
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							this.req('POST', '/invoice/resendFinalInvoice/' + invoice.id).then((response) => {
								if (response.status) {
									this.$swal('Success', 'Invoice resend success', 'success');
									this.updateInvoice(invoice);
								}else{
									let errorMessage = 'Invoice resend ERROR';
									
									this.$swal('Error', errorMessage, 'error');
								}
							});
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'createCreditInvoice': // resend invoice
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							this.req('POST', '/invoice/createCreditInvoice/' + invoice.id).then((response) => {
								if (response.status) {
									if (this.filter) {
										this.loadInvoices(undefined, this.filter.year, this.filter.month);
									} else {
										this.$router.push({ name: 'invoices' });
									}
									this.$swal('Success','Credit Invoice Created', 'success');
								} else if (response.message) {
									this.$swal('Error', response.message, 'error');
								} else {
									this.$swal('Error', 'Credit Invoice NOT Created', 'error');
								}
							});
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'D': // decline draft / invoice
					var comment = prompt("What is the reason for declining the invoice?", "");

					if (comment != null && comment != "") {
						this.req('POST', '/invoice_comment/insert', { 'invoice_id': invoice.id, 'comment': comment }).then((response) => {
							if (response.status) {
								this.req('POST', '/invoice/decline/' + invoice.id, {}).then((response) => {
									if (response.status) {
										this.updateInvoice(invoice);
									}
								});
							}
						});
					}
					break;
				case 'VC': // view comments
					this.req('GET', '/invoice_comment/getComment/' + invoice.id).then((response) => {
						if (response.status) {
							this.modal.comments = response.data;
						}
					});
					break;
				case 'C': // cancel invoice
					var c = confirm('Are you sure to delete invoice #' + invoice.id);
					if (!c) { return; }
					this.req('GET', '/invoice/delete/' + invoice.id).then((response) => {
						if (response.status) {
							let index = this.invoices.indexOf(invoice);
							if (index !== -1) {
								this.invoices.splice(index, 1);
							}
							if (this.invoices.length == 0) {
								this.$router.push({ name: 'invoices' });
							}
						}
					});
					break;
			}
		},
	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},
	mounted() {
		this.loadInvoices(this.$route.params.id);
	}
}
</script>
