<template>
<div>
	<div class="row">
		<div class="col-md-12">
			<h2>Suppliers</h2>
			<SbaCrudTable v-if="supplierTable" :my-table="supplierTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<h2>Contracts</h2>
			<SbaCrudTable v-if="contractTable" :my-table="contractTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<h2>Contract overrides</h2>
			<SbaCrudTable v-if="contractOverrideTable" :my-table="contractOverrideTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<h2>Automatic surcharges</h2>
			<SbaCrudTable v-if="surchargeTable" :my-table="surchargeTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<h2>Invoice addresses</h2>
			<div class="panel panel-default">
				<div class="panel-heading">
					<span>*You can use the following variables in "Addres on invoice": #country_code#, #vat#, #iata_code#</span>
				</div>
			</div>
			<SbaCrudTable v-if="invoiceAddressesTable" :my-table="invoiceAddressesTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<h2>Cars Supplier Override Prices</h2>
			<SbaCrudTable v-if="carOverrideTable" :my-table="carOverrideTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<h2>Cars</h2>
			<SbaCrudTable v-if="carsTable" :my-table="carsTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<h2>Currencies</h2>
			<SbaCrudTable v-if="currencyTable" :my-table="currencyTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<h2>Destinations</h2>
			<SbaCrudTable v-if="destinationTable" :my-table="destinationTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<h2>Apis</h2>
			<SbaCrudTable v-if="apiTable" :my-table="apiTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<h2>Mailboxes</h2>
			<SbaCrudTable v-if="mailboxTable" :my-table="mailboxTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<h2>Countries</h2>
			<SbaCrudTable v-if="countryTable" :my-table="countryTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<h2>Organizations</h2>
			<SbaCrudTable v-if="organizationTable" :my-table="organizationTable" />
		</div>
	</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data () {
		return {
			supplierTable: null,
			contractTable: null,
			contractOverrideTable: null,
			invoiceAddressesTable: null,
			carsTable: null,
			carOverrideTable: null,
			organizationTable: null,
			currencyTable: null,
			destinationTable: null,
			apiTable: null,
			mailboxTable: null,
			countryTable: null,
			surchargeTable: null,
		}
	},
	methods: {
		copyContract: function(btn, row, blnSubmit) {
			// copy row without reactivity
			let copyRow = JSON.parse(JSON.stringify(row));
			//set date fields to Date object
			copyRow['driving_time'] = new Date(copyRow['driving_time']);
			copyRow['valid_from'] = new Date(copyRow['valid_from']);
			// unset id from row
			delete copyRow['id'];
			
			// copy create action without reactivity
			let actionBtn = JSON.parse(JSON.stringify(this.contractTable.buttons['C']));
			
			// perform action
			this.contractTable.toggleButton(actionBtn, copyRow, false);
		},
		recalculate: function(btn, row, blnSubmit) {
			let accept = confirm("Are you sure to recalculate " + row['name'] + "?");
			if (accept != true) {
				return;
			}

			this.req('GET', '/supplier/recalculate/' + row['id']).then(function(response) {
				if (response['status']) {
					alert("Successfully recalculated supplier");
				} else {
					alert("Error recalculating supplier\n" + response['message']);
				}
			}, function(error) {
				alert("Error recalculating supplier\nBackend error");
			})
		},
		onChangeSupplierField: function(tableName, fieldName, fieldValue, eventType) {
			switch (tableName) {
				case this.supplierTable.name:
					if (fieldName == 'email') {
						if (typeof fieldValue === 'string') {
							if (fieldValue.indexOf(' ') >= 0 || fieldValue.indexOf('@') < 0) {
								alert("Invalid emailaddress");
							}
						}
					}
					break;
			}
		},

	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},
	mounted () {
		this.supplierTable = {
			name: 'suppliers',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			getCSV: true,
			tableFold: false,
			controller: 'supplier',
			listUndefinedFields: true,
			autoChangeTo: ['organization_id', 'country_id'],
			onChangeFieldFunction: this.onChangeSupplierField,
			fields: [
				'organization_id',
				'currency_id',
				'name',
				'contact',
				'vat_number',
				'bank_name',
				'bank_number',
				'bank_swift',
				'email',
				'info_email',
				'telephone',
				'website',
				'destinations',
				'ust_id',
				'country_id',
				'no_invoice',
				'address',
				'postal_code',
				'country',
				'day_fare',
				'night_fare',
				'vat_percentage',
			],
			subQueries: {
				'currency': {
					controller: 'currency',
					lfield: 'currency_id',
					ffield: 'id',
				},
				'destination': {
					controller: 'destination',
					lfield: 'id',
					ffield: 'destination_id',
				},
				'supplier_destination': {
					controller: 'supplier_destination',
					lfield: 'id',
					ffield: 'supplier_id',
				},
			},
			fieldDetails:{
				'id': {
					hideEdit: true,
					hideList: true,
				},
				'organization_id': {
					label: 'Organization',
					required: true,
				},
				'currency_id': {
					label: 'Currency',
					changeTo: 'currency.name',
					required: true,
				},
				'destinations': {
					type: 'multiselect',
					link: 'supplier_destination',
					changeTo: ['destination.iata', ' - ', 'destination.name'],
					sortAsc: true,
				},
				'name': {
					required: true,
				},
				'email': {
					required: true,
					label: 'Invoice Email',
				},
				'info_email': {
					hideList: true,
					label: 'Operations Email',
				},
				'website': {
					hideList: true,
				},
				'ust_id': {
					hideList: true,
					hideEdit: true,
				},
				'bank_number': {
					hideList: true,
				},
				'bank_swift': {
					hideList: true,
				},
				'bank_name': {
					hideList: true,
				},
				'country_id': {
					hideList: true,
					label: 'Country',
					required: true,
				},
				'no_invoice': {
					hideList: true,
				},
				'address': {
					hideList: true,
				},
				'postal_code': {
					hideList: true,
				},
				'country': {
					hideList: true,
				},
				'day_fare': {
					hideList: true,
				},
				'night_fare': {
					hideList: true,
				},
				'vat_percentage': {
					required: true,
					hideList: true,
				}
			},
			buttons: {
				'RC': {
					'id': 'rc',
					'name': 'Recalculate',
					'label': 'Recalculate',
					'confirmName': 'Recalculate',
					'confirmClass': 'success',
					//'inline': $scope.user['rights']['supplier']['recalculate'] !== undefined && $scope.user['rights']['supplier']['recalculate'] === true,
					'inline': true,
					'icon': 'sync',
					'action': 'recalculate',
					'function': 'recalculate',
					'parentScope': true,
				},
			},
		};
		this.contractTable = {
			name: 'Contracts',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'contract',
			listUndefinedFields: true,
			autoChangeTo: ['country_id', 'currency_id', 'department_id', 'supplier_id', 'organization_id'],
			subQueries: {
				'hotel': {
					controller: 'destination',
					function: 'getHotels',
					lfield: 'hotel_destination_id',
					ffield: 'id',
				},
				'airport': {
					controller: 'destination',
					function: 'getAirports',
					lfield: 'iata_destination_id',
					ffield: 'id',
				},
			},
			fieldDetails:{
				'id': {
					label: 'Contract number',
					hideEdit: true,
					hideList: false,
				},
				'organization_id': {
					required: true,
				},
				'country_id': {
					required: true,
				},
				'currency_id': {
					required: true,
				},
				'department_id': {
					required: true,
				},
				'supplier_id': {
					required: true,
				},
				'day_fare': {
					required: true,
					default: 0,
				},
				'night_fare': {
					required: true,
					default: 0,
				},
				'vat_percentage': {
					required: true,
					default: 0,
				},
				'valid_from': {
					sortAsc: true,
					required: true,
				},
				'iata_destination_id': {
					changeTo: ['airport.id', ' - ', 'airport.iata', ' - ', 'airport.name'],
					required: true,
				},
				'hotel_destination_id': {
					changeTo: ['hotel.id', ' - ', 'hotel.city', ' - ', 'hotel.name'],
				},
				'driving_time': {
					type: 'time',
					required: true,
				},
				'driving_distance': {
					label: 'driving distance (KM)'
				}
			},
			buttons: {
				'CP': {
					'id': 'cp',
					'name': 'Copy',
					'label': 'Copy',
					'confirmName': 'Copy',
					'confirmClass': 'success',
					'icon': 'copy',
					'inline': this.currentUser['rights']['contract']['insert'] !== undefined && this.currentUser['rights']['contract']['insert'],
					'parentScope': true,
					'action': 'copyContract',
					'function': 'insert',
				},		
			},
		};
		this.contractOverrideTable = {
			name: 'Contract overrides',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'contract_override',
			listUndefinedFields: true,
			subQueries: {
				'hotel': {
					controller: 'destination',
					function: 'getHotels',
					lfield: 'destination_id',
					ffield: 'id',
				},
			},
			fields: [
				'id',
				'destination_id',
				'day_of_week',
				'flight_number',
				'driving_time',
				'day_fare',
				'night_fare',
				'comments'
			],
			fieldDetails: {
				'id': {
					hideEdit: true,
					hideList: true,
				},
				'destination_id': {
					changeTo: ['hotel.id', ' - ', 'hotel.city', ' - ', 'hotel.name'],
					required: true,
				},
				'day_of_week': {
					type: 'enum',
					options: [
						{
							id: '0',
							name: '',
						},
						{
							id: '1',
							name: 'Monday',
						},
						{
							id: '2',
							name: 'Tuesday',
						},
						{
							id: '3',
							name: 'Wednesday',
						},
						{
							id: '4',
							name: 'Thursday',
						},
						{
							id: '5',
							name: 'Friday',
						},
						{
							id: '6',
							name: 'Saturday',
						},
						{
							id: '7',
							name: 'Sunday',
						},
						
					],
				},
				'driving_time': {
					required: false,
				},
				'day_fare': {
					label: 'Dayfare',
					required: false,
					default: 0,
					type: 'number'
				},
				'night_fare': {
					label: 'Nightfare',
					required: false,
					default: 0,
					type: 'number'
				},
				'comments': {
					label: 'Comments'
				}
			}
		};
		this.invoiceAddressesTable = {
			name: 'Invoice addresses',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'invoice_address',
			listUndefinedFields: true,
			autoChangeTo: ['country_id', 'supplier_id', 'organization_id'],
			subQueries: {
				destination: {
					controller: 'destination',
					function: 'getairports',
					lfield: 'arrival_airport_iata',
					ffield: 'iata',
				},
				department: {
					controller: 'department',
					lfield: 'department',
					ffield: 'name',
				},
			},
			fieldDetails:{
				'id': {
					hideEdit: true,
					hideList: true,
				},
				'country_id': {
					label: 'Country',
				},
				'supplier_id': {
					label: 'Supplier',
				},
				'department': {
					type: 'options',
					changeTo: ['department.name'],
				},
				'arrival_airport_iata': {
					label: 'IATA',
					type: 'combobox',
					changeTo: ['destination.iata', ' - ', 'destination.name'],
				},
				'valid_from': {
					label: 'Valid from',
				},
				'address': {
					label: 'Address on invoice*',
				},
				'invoice_email': {
					label: 'Invoice email',
				}
			},
		};
		this.carsTable = {
			name: 'cars',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'car',
			listUndefinedFields: true,
			fieldDetails:{
				'id': {
					hideEdit: true,
					hideList: true,
				}
			},
		};
		this.organizationTable = {
			name: 'Organizations',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'organization',
			listUndefinedFields: false,
			fields: [
				'name',
				'brand_file',
				'subdomain_name',
			],
			fieldDetails:{
				'id': {
					hideEdit: true,
					hideList: true,
				},
				'subdomain_name': {
					label:'subdomain'
				},
			},
		};
		this.carOverrideTable = {
			name: 'car_supplier_overrides',
			label: 'Cars Supplier Override',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'car_supplier_override',
			listUndefinedFields: false,
			fields: ['car_id', 'supplier_id', 'day_fare', 'night_fare'],
			autoChangeTo: ['car_id', 'supplier_id'],
		};
		this.currencyTable = {
			name: 'currencies',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'currency',
			listUndefinedFields: true,
			fieldDetails:{
				'id': {
					hideEdit: true,
					hideList: true,
				}
			},
		};
		this.destinationTable = {
			name: 'destinations',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'destination',
			listUndefinedFields: true,
			fieldDetails:{
				'id': {
					hideEdit: true,
					hideList: true,
				},
				'latitude': {
					hideEdit: true,
					hideList: true,
				},
				'longitude': {
					hideEdit: true,
					hideList: true,
				},
				'icao': {
					hideEdit: true,
					hideList: true,
				},
				'altitude': {
					hideEdit: true,
					hideList: true,
				},
				'timezone': {
					hideEdit: true,
					hideList: true,
				},
				'dst': {
					hideEdit: true,
					hideList: true,
				},
			},
		};
		this.apiTable = {
			name: 'apis',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'api',
			listUndefinedFields: true,	

		};
		this.mailboxTable = {
			name: 'mailboxes',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'mailbox',
			listUndefinedFields: true,	
			autoChangeTo:[
				'organization_id',
			],

		};
		this.surchargeTable = {
			name: 'Automatic surcharges',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'surcharge',
			listUndefinedFields: true,	
			autoChangeTo:[
				'department_id',
				'supplier_id',
			],
			fields:[
				'id',
				'name',
				'department_id',
				'iata',
				'supplier_id',
				'amount',
				'vat'
			],
			fieldDetails:{
				'id': {
					hideEdit: true,
					hideList: true,
				},
				'name':{
					'label': "Name"
				},
				'department_id': {
					'label': "Organization"
				},
				'iata': {
					'label': "Airport"
				},
				'supplier_id': {
					'label': "Supplier"
				},
				'amount': {
					'label': "Surcharge",
					'type': 'number',
				},
				'vat': {
					'label': "Surcharge VAT",
					'type': 'number',
				},
			},
		};
		this.countryTable = {
			name: 'Countries',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			autoChangeTo: ['continent_id'],
			controller: 'country',
			fields: [
				'id',
				'continent_id',
				'name'
			],
			fieldDetails:{
				'id': {
					hideEdit: true,
					hideList: true,
				},
				'continent_id': {
					'label': 'Continent',
				},
			},
			buttons: {
				'C': {
					'id': 'c',
					'name': 'Add Country',
					'label': 'Add Country',
					'confirmName': 'Add Country',
					'confirmClass': 'success',
					'header': true,
					'icon': 'plus-square',
					'action': 'create',
				},
			},
		};
	}
}
</script>
