<template>
<div>
	<div class="mobile-hidden">
		<div class="row">
			<div class="col-md-12">
				<div class="page-title">
					<h1 style="height: 44px;" v-if="!selectedTrips || selectedTrips.length <= 0">
						<i class="fa fa-book" aria-hidden="true"></i> Logbook
						<font-awesome-icon v-if="loadingDate"  style="display: inline-block; float:right; margin-top: 5px; margin-right:5px;" class="fa-spin" :icon="'spinner'" spin ></font-awesome-icon>

					</h1>
					<h1 style="height: 44px;" v-if="selectedTrips && selectedTrips.length > 0">
						<i class="fa fa-book" aria-hidden="true"></i> Details - {{ selected.iata }} - {{ selected.day + '-' + selected.month + '-' + selected.year}}
						<button type="button" class="btn btn-warning" style="float: right;" @click="backToOverview()">Back to overview</button>
					</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="row" v-show="!selectedTrips || selectedTrips.length <= 0">
		<div class="col-md-4">
			<div class="form-group">
				<select class="form-control" id="department" v-model="department" @change="changeDepartment()">
					<template v-for="department in departments" :key="department">   
						<option :value="department.name">{{department.name}}</option>
					</template>
				</select>
			</div>
		</div>
		<div class="col-md-4 text-center">
			<span class="float-left">
				<i class="fas fa-arrow-left" aria-hidden="true" @click="previousMonth()">
				</i>
			</span>
			{{ monthNames[month-1] }} - {{ year }}
			<span class="float-right">
				<i class="fas fa-arrow-right" aria-hidden="true" @click="nextMonth()">
				</i>
			</span>
		</div>
		<div class="col-md-4">
			&nbsp;
		</div>
		<div class="col-md-12" style="overflow-x: scroll; max-width:100%">
			<table class="table table-striped table-bordered overview logbooktable">
				<thead>
					<tr>
						<th>IATA</th>
						<th class="tripHeader" v-for="day in getDayList(month, year)" :key="day">{{ day }}</th>
						<th>#</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody v-if="tripAmount && tripAmount[year] && tripAmount[year][month < 10 ? '0' + month : month]">
					<tr v-for="(row, iata) in tripAmount[year][month < 10 ? '0' + month : month]" :key="row">
						<td style="white-space: nowrap;">{{ iata }}</td>
						<td style="white-space: nowrap;" class="tripField" :class="getTripFieldClass(row, day)" :title="getTripFieldTitle(row, day)" v-for="day in getDayList(month, year)" :key="day" @click="(row[day < 10 ? '0' + day : day] || 0) == 0 || showTrips(iata, year, month, day)">{{ (row[addLeadingZero(day)] && row[addLeadingZero(day)]['trips']) || 0 }}</td>
						<td style="white-space: nowrap;" v-if="tripAmount && tripAmount[year]">{{ tripAmount[year][addLeadingZero(month)][iata]['trips'] || 0  }}</td>
						<td style="white-space: nowrap;" v-if="tripAmount && tripAmount[year]" :title="(parseFloat(tripAmount[year][month < 10 ? '0' + month : month][iata]['total']) || 0).toFixed(2) + ' + ' + (parseFloat(row.total_fees) || 0).toFixed(2)">{{ ((parseFloat(tripAmount[year][addLeadingZero(month)][iata]['total']) || 0) + parseFloat(row.total_fees)).toFixed(2) }}{{ row.total_fees > 0 ? '*' : '' }} {{ row.supplier }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div v-show="selectedTrips && selectedTrips.length > 0">
		<h2>Search</h2>
		<div>
			<label>Filter your trips by this search: </label><input class="form-control" type="text" placeholder="Search" v-model="tripFilter" />
		</div>
		<h2>Trips</h2>
		<div style="overflow-x: scroll; max-width:100%">
			<table class="table ng-scope tableTripEven"  v-for="(trip, index) in (filteredTrips ? filteredTrips : [])" :key="trip">
				<thead>
					<tr>
						<th>#</th>
						<th>Department</th>
						<th>Date</th>
						<th>From</th>
						<th>To</th>
						<th>Supplier</th>
						<th>Flight-nr</th>
						<th>Nightfare</th>
						<th># of people</th>
						<th>Price</th>
						<th>Comments</th>
					</tr>
				</thead>
				<tbody>
					<tr :class="(index % 2 ? 'tableTripOdd' : 'tableTripEven')">
						<td>{{ (index + 1) + ' (' + trip.id + ')'}}</td>
						<td>{{ trip.department }}</td>
						<td>{{ trip.date }}</td>
						<td>{{ trip.from_id == 0 ? trip.from_desc : trip.from.type + ' - ' +  trip.from.name + ' (' + trip.from.country  + ')' }}</td>
						<td>{{ trip.to_id == 0 ? trip.to_desc : trip.to.type + ' - ' +  trip.to.name + ' (' + trip.to.country  + ')' }}</td>
						<td>{{ trip.supplier.name }}</td>
						<td>{{ trip.flightnr }}</td>
						<td>{{ trip.nightfare }}</td>
						<td>{{ trip.numberofpeople }}</td>
						<td>{{ parseFloat(trip.price).toFixed(2) + ' ' + trip.supplier.currency }}</td>
						<td>{{ trip.comments }}</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td colspan="9">
							<h3>
								Surcharges <i style="cursor: pointer;" @click="addSurcharge(trip);" v-if="trip.is_invoiced == '0' && currentUser.role.id > 2" aria-hidden="true" class="fas fa-plus ng-scope"></i>
							</h3>
							<table class="table table-striped" ng-if="trip.fees.length">
								<thead>
									<tr>
										<th>#</th>
										<th>Name</th>
										<th>Amount</th>
										<th>VAT percentage</th>
										<th>Extra Trip</th>
										<th>Reason</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(fee, index) in trip.fees" :key="index">
										<td>{{ index + 1 }}</td>
										<td>{{ fee.name }}</td>
										<td>{{ fee.amount }}</td>
										<td>{{ fee.vat_percentage }}%</td>
										<td>{{ fee.extra_trip }}</td>
										<td>{{ fee.reason }}</td>
										<td><i @click="deleteSurcharge(trip, fee);" v-if="trip.is_invoiced == '0' && currentUser.role.id > 2" aria-hidden="true" class="fa fa-trash"></i></td>
									</tr>
									<tr>
										<td>&nbsp;</td>
										<td>Fee Total</td>
										<td>{{ getFeeTotal(trip) + ' ' +  trip.supplier.currency }}</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td colspan="3"><b>Total Price</b></td>
						<td colspan="4">&nbsp;</td>
						<td colspan="3"><b>{{parseFloat(trip.price).toFixed(2)}} + {{parseFloat(getFeeTotal(trip)).toFixed(2)}} = {{(parseFloat(trip.price) + parseFloat(getFeeTotal(trip))).toFixed(2) + ' ' +  trip.supplier.currency}}</b></td>
					</tr>
					<tr v-if="trip.is_invoiced == '0' && currentUser.role.id > 2" class="ng-scope">
						<td colspan="8">&nbsp;</td>
						<td colspan="2">
							<button @click="deleteTrip(trip);" class="btn btn-danger btn-block">Delete trip</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<!-- Modal -->
	<div ref="tripModal" class="modal fade" role="dialog" tabIndex="-1" >
		<div class="modal-dialog" role="document">
			<!-- Modal content -->
			<div class="modal-content" v-if="modal.trip">
				<div class="modal-header">
					<h4 class="modal-title">Trip - add surcharge</h4>
				</div>
				<div class="modal-body">
					<form id="surchargeForm" @submit.prevent="addSurcharge(modal.trip)">
						<div class="form-group">
							<label for="name">Name</label>
							<input id="name" class="form-control" v-model="surcharge.name" type="text" required />
						</div>
						<div class="form-group">
							<label for="reason">Reason</label>
							<input id="reason" class="form-control" v-model="surcharge.reason" type="text" />
						</div>
						<div class="form-group">
							<label for="extra_trip">Extra Trip</label>
							<select id="extra_trip" class="form-control" v-model="surcharge.extra_trip">
								<option value="No">No</option>
								<option value="Single">Single</option>
								<option value="Double">Double</option>
								<option value="SingleNightfare">Single (Nightfare)</option>
								<option value="DoubleNightfare">Double (Nightfare)</option>
							</select>
						</div>
						<div class="form-group" v-show="surcharge.extra_trip == 'No'">
							<label for="amount">Amount</label>
							<input id="amount" class="form-control" v-model="surcharge.amount" type="number" step="any" />
						</div>
						<div class="form-group" v-show="surcharge.extra_trip == 'No'">
							<label for="vat_percentage">VAT percentage</label>
							<input id="vat_percentage" class="form-control" v-model="surcharge.vat_percentage" type="number" step="any" />
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<span>{{ (modal.trip && modal.trip.surcharge && modal.trip.surcharge.status) ? 'Surcharge added successfully to the trip!' : '' }}</span>
					<button type="submit" class="btn btn-success" :disabled="modal.trip.surcharge && modal.trip.surcharge.status" form="surchargeForm">Add</button> 
					<button type="button" class="btn btn-default" :disabled="modal.trip.surcharge && modal.trip.surcharge.status" @click="modal.trip = false;closeModal('tripModal')">Close</button>
				</div>
			</div>

		</div>
	</div>


</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data () {
		return {
			department: '',
			tripAmount: {},
			monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			loading: {},
			totalweeks: 0,
			calander: {},
			date: new Date(),
			currentYear: '',
			currentMonth: '',
			maxDays: '',
			selected: {},
			selectedTrips: [],
			tripFilter:'',
			loadingDate: false,
			modal: {
				trip: false,
			},
			departments : null,
			month: 0,
			year: 2022,
			surcharge: {
				extra_trip: 'No'
			}
		}
	},
	methods: {
		/* change department toggle */
		changeDepartment: function() {
			this.loadingDate = true;
			this.tripAmount = {};
			this.loadTripAmount(this.year, this.month);
		},
		daysInMonth: function(month, year) {
			return new Date(year, month, 0).getDate();
		},
		getDayList: function(month, year) {
			let lastDay = new Date(year, month, 0).getDate();
			let days = [];
			for (let i = 1; i <= lastDay; i++) {
				days.push(i);
			}
			return days;
		},
		updateDate: function(){
			let maxDays = this.daysInMonth(this.month, this.year);
			this.totalWeeks = Math.ceil(maxDays /7);
			this.setDateInfo(this.year, this.month, maxDays);
		},
		//set date values;
		changeDate: function(type,calc, amount){
			//switch between options
			switch (type) {
				//change month values
				case 'month':
					if(calc == "-"){
						this.month -= amount;
					}else if(calc == "+"){
						this.month += amount;
					}
					break;
				//change week values
				case 'week':
					if(calc == "-"){
						this.week -= amount;
					}else if(calc == "+"){
						this.week += amount;
					}
					break;
			}

			//change week count
			if(this.week > this.totalWeeks){
				this.month += 1;
				this.week = 0;
				this.updateDate();
			}
			if(this.week < 0){
				this.month -= 1;
				this.updateDate();
				this.week = this.totalWeeks;
			}

			//change month count
			if(this.month <= 0){
				this.month = 12;
				this.year -= 1;
				this.updateDate();
			}
			if(this.month > 12){
				this.month = 1;
				this.year += 1;
				this.updateDate();
			}

			if (this.tripAmount[this.year] == undefined || this.tripAmount[this.year][(this.month < 10 ? '0' + this.month : this.month)] == undefined) {
				this.loadTripAmount(this.year, this.month);
			}
			
			this.$router.push({ path: 'logbook', query: { month: this.year + '' + (this.month < 10 ? '0' + this.month : this.month) }});
		},
		//change month -1
		previousMonth: function(){
			this.changeDate("month", "-", 1);
		},
			//change month +1
		nextMonth: function(){
			this.changeDate("month", "+", 1);
		},

		//change week -1
		previousWeek: function(){
			this.changeDate("week", "-", 1);
		},

		//change week +1
		nextWeek: function(){
			this.changeDate("week", "+", 1);
		},
		addLeadingZero: function (input) {
			if (input < 10) {
				input = '0' + input
			}
			return input;
		},
		getTripFieldClass: function (row, day){
			let customClass = '';
			if (row[day < 10 ? '0' + day : day]) {
				customClass += 'available';
			}
			if (row[day < 10 ? '0' + day : day] && row[day < 10 ? '0' + day : day]['total_fees'] && row[day < 10 ? '0' + day : day]['total_fees'] > 0) {
				customClass += ' has_fee';
			}

			//add class for new contract
			if (row[day < 10 ? '0' + day : day] && row[day < 10 ? '0' + day : day]['contract_new']) {
				customClass += ' has_new_contract';
			}
			
			//add class for custom price
			if (row[day < 10 ? '0' + day : day] && row[day < 10 ? '0' + day : day]['custom_price']) {
				customClass += ' has_custom_price';
			}
			return customClass; 
		},
		getTripFieldTitle: function (row, day){
			let customTitle = [];
			if (!row[day < 10 ? '0' + day : day]) {
				customTitle.push('No trips');
			}
			if (row[day < 10 ? '0' + day : day] && row[day < 10 ? '0' + day : day]['total_fees'] && row[day < 10 ? '0' + day : day]['total_fees'] > 0) {
				customTitle.push('Fee');
			}

			//add class for new contract
			if (row[day < 10 ? '0' + day : day] && row[day < 10 ? '0' + day : day]['contract_new']) {
				customTitle.push('New Contract');
			}
			
			//add class for custom price
			if (row[day < 10 ? '0' + day : day] && row[day < 10 ? '0' + day : day]['custom_price']) {
				customTitle.push('Custom Trip Price');
			}
			return customTitle.join(', '); 
		},
		//convert date information
		setDateInfo: function(year, month, dayCount){
			//pre-set data
			let day = 0
			let days = [];
			let arrDate = {
				year: year,
				month: month,
			}
			//loop through max days
			for(let i=0; i < dayCount; i++){
				day = i + 1;
				days.push(day);
				if(arrDate['days'] === undefined){
					arrDate['days'] = [];
				}
				arrDate['days'] = days;
			}
			this.calander = arrDate;
		},
		backToOverview: function() {
			this.selectedTrips = false;
			this.$router.push({ path: 'logbook', query: { month: this.year + '' + (this.month < 10 ? '0' + this.month : this.month) }});
		},
		showTrips: function(iata, year, month, day) {
			this.selected = {
				iata: iata,
				year: year,
				month: month,
				day: day,
				department: this.department,
			}
			
			this.$router.push({ path: 'logbook', query: { 
				day: year + '' + (month < 10 ? '0' + month : month) + (day < 10 ? '0' + day : day),
				iata: iata,
				department: this.department,
			}});
			
			//get data from backend
			this.req('POST', '/trip/getSelectedTrips', this.selected).then((response) => {
				if (response.status) {
					this.selectedTrips = response.data;
				}
			});
		},
		loadTripAmount(year, month) {
			this.loadingDate = true;
			this.loading[year + month] = true;
			this.req('GET', '/trip/getTripAmount/' + year + '/' + month + '/' + this.department).then((response) => {
				this.tripAmount = Object.assign({}, this.tripAmount, response.data);
				this.loading[year + month] = false;
				this.loadingDate = false;
			}, () => {
				this.loadingDate = false;
			});
		},
		addSurcharge: function(trip) {
			if (this.modal === undefined) { this.modal = {}; }
			
			if (this.modal.trip) {
				this.surcharge.trip_id = this.modal.trip.id;

				this.req('POST', '/fee/insert', this.surcharge).then((response) => {
					this.modal.trip.surcharge = {
						status: response.status,
					}
					if (response.status) {
						this.req('GET', '/fee/getWith/' + response.data.id).then((response) => {
							if (response.status) {
								trip.fees.push(response.data[0]);
							}
						});
						
						setTimeout(() => {
							delete this.modal.trip.surcharge;
							this.modal.trip = false;
							this.closeModal('tripModal');
						}, 2000);
					}
				});
			} else {
				this.modal.trip = trip;
				this.surcharge = {
					'extra_trip': 'No',
				};
				if (this.modal.trip.supplier.vat_percentage) {
					this.surcharge.vat_percentage = parseFloat(this.modal.trip.supplier.vat_percentage);
				}
			}
			
			this.openModal('tripModal');
		},
		openModal: function(modalRef){
			let modal = this.$refs[modalRef];
			
			modal.classList.add("show");
			modal.classList.add("show-modal");
		},
		closeModal: function(modalRef) {
			let modal = this.$refs[modalRef];
			
			modal.classList.remove("show");
			modal.classList.remove("show-modal");
		},

		getFeeTotal: function(trip) {
			let feeTotal = 0.0;
			trip.fees.forEach((fee, index) => {
				feeTotal += parseFloat(fee.amount);
			});
			return feeTotal;
		},
		deleteTrip: function(trip) {
			let isConfirmed = confirm("Are you sure you want to delete this trip?");
			if (isConfirmed != true) {
				return;
			}

			this.req('GET', '/trip/delete/' + trip.id).then((response) => {
				if (response.status){
					let index = this.selectedTrips.indexOf(trip);
					this.selectedTrips.splice(index, 1);
				}
			});
		},
		deleteSurcharge: function(trip, fee) {
			this.req('GET', '/fee/delete/' + fee.id).then((response) => {
				if (response.status) {
					let index = trip.fees.indexOf(fee);
					trip.fees.splice(index, 1);
				}
			});
		},
		fetchDepartments: function() {
			this.req('GET', '/department/get/').then((response) => {
				if(response.status){
					this.departments = response.data;

					//set first result as default
					if (this.departments[0] != undefined && this.departments[0].name != undefined) {
						this.department = this.departments[0].name;
					}
				}

				this.loadTripAmount(this.year, this.month);
			});
		}
	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		}),
		filteredTrips: function() {
			if (!Array.isArray(this.selectedTrips)) {
				return [];
			}
			return this.selectedTrips.filter((selectedTrip) => {
				if (!this.tripFilter) {
					return true;
				}
				
				return JSON.stringify(selectedTrip).toLowerCase().includes(this.tripFilter.toLowerCase());
			});
		}
	},
	mounted () {
		this.fetchDepartments();
		this.currentYear = this.date.getFullYear();
		this.currentMonth = this.date.getMonth() + 1;

		this.year = this.date.getFullYear();
		this.month = this.date.getMonth() + 1;

		if (this.$route.query) {
			let search = this.$route.query;
			if (search['month'] !== undefined && search['month'].length == 6) {
				this.year = parseInt(search['month'].substring(0, 4));
				this.month = parseInt(search['month'].substring(4, 6));
			}
			if (search['day'] !== undefined && search['day'].length == 8 && search['iata'] !== undefined && search['iata'].length == 3) {
				let year = parseInt(search['day'].substring(0, 4));
				let month = parseInt(search['day'].substring(4, 6));
				let day = parseInt(search['day'].substring(6, 8));
				let iata = search['iata'];
				this.showTrips(iata, year, month, day);
			}
		}

		//set max days of month
		this.maxDays = this.daysInMonth(this.currentMonth, this.currentYear);
	}
}
</script>
