<template>
<div>
	<h2>Trips</h2>
	<SbaCrudTable v-if="tripTable" :my-table="tripTable" />
	<h2>Invoices</h2>
	<p>It is better to cancel the invoice than manipulate the data, editing will cause differences between an invoice and the logbook.</p>
	<SbaCrudTable v-if="invoiceTable" :my-table="invoiceTable" />
	<br />
	<SbaCrudTable v-if="invoiceTripTable" :my-table="invoiceTripTable" />
	<br />
	<SbaCrudTable v-if="invoiceFeeTable" :my-table="invoiceFeeTable" />
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data () {
		return {
			tripTable: null,
			invoiceTable: null,
			invoiceTripTable: null,
			invoiceFeeTable: null,
		}
	},
	methods: {


	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},
	mounted () {
		this.tripTable = {
			name: 'Trips',
			controller: 'trip',
			listUndefinedFields: true,
			allowDeleteMany: true,
			selectable: true,
			pageLimit: 5,
			autoChangeTo: ['car_id', 'supplier_id'],
		},
		this.invoiceTable = {
			name: 'Invoices',
			controller: 'invoice',
			listUndefinedFields: true,
			allowDeleteMany: true,
			selectable: true,
			pageLimit: 5,
			autoChangeTo: ['supplier_id', 'status_id', 'organization_id'],
		},
		this.invoiceTripTable = {
			name: 'Invoice Trips',
			controller: 'invoice_trip',
			listUndefinedFields: true,
			allowDeleteMany: true,
			selectable: true,
			pageLimit: 5,
			autoChangeTo: ['invoice_id'],
		},
		this.invoiceFeeTable = {
			name: 'Invoice Fees',
			controller: 'invoice_fee',
			listUndefinedFields: true,
			allowDeleteMany: true,
			selectable: true,
			pageLimit: 5,
			autoChangeTo: ['invoice_id'],
		}
	}
}
</script>
