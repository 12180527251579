<template>
	<div>
		<form class="form-inline mb-2" @submit.prevent="report">
			<label class="mr-2" for="fromDate">From</label>
			<input type="date" class="form-control mr-2" id="fromDate" v-model="fromDate" />

			<label class="mr-2" for="toDate">To</label>
			<input type="date" class="form-control mr-2" id="toDate" v-model="toDate" />
			
			<button type="submit" class="btn btn-primary">Generate Trip Report</button>
		</form>
		
		<SbaCrudTable v-if="reportingTable" :my-table="reportingTable" />
	</div>
</template>
<script>
import moment from 'moment';

export default {
	data () {
		return {
			fromDate: null,
			toDate: null,
			reportingTable: null,
		}
	},
	methods: {
		report: function() {
			if (this.reportingTable == null) {
				this.reportingTable = {
					name: 'Trip report',
					controller: 'trip_report',
					function: 'getReport/' + this.fromDate + '/' + this.toDate,
					pageLimit: 10,
					showRowCount: true,
					showStatistics: false,
					getCSV: true,
					getCSVurl: 'getReportCSV/' + this.fromDate + '/' + this.toDate + '/true',
					fields: [
						'name',
						'continent',
						'country',
						'price_per_minute',
						'price_per_km',
						'trip_amount',
						'price_total',
						'price_avg',
						'price_std',
						'surcharge_amount',
						'surcharge_total',
						'surcharge_avg',
						'surcharge_std',
					],
				};
			} else {
				this.reportingTable.function = 'getReport/' + this.fromDate + '/' + this.toDate;
				this.reportingTable.getCSVurl = 'getReportCSV/' + this.fromDate + '/' + this.toDate + '/true',
				this.reportingTable.call('initiate');
			}
			
		},
	},
	created () {
		this.$moment = moment;
		
		var date = new Date();
		this.fromDate = this.$moment(new Date(date.getFullYear() - 1, 0, 1)).format('YYYY-MM-DD');
		this.toDate = this.$moment(new Date(date.getFullYear() - 1, 11, 31)).format('YYYY-MM-DD');
	},
}
</script>