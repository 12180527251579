<template>
<div>
	<div class="row">
		<div class="col-md-12" v-if="userTable && userTable.data">
			<h2>Switch to other user</h2>
			User:
			<select class="form-control" style="display: inline-block; width: auto;" v-model="selectedUser">
				<option value="?" selected="selected"></option>
				<option :label="user.name" :value="user.username" v-for="user in userTable.data.rows" :key="user">{{user.name}}</option>
			</select> &nbsp;
			<button class="btn btn-primary" @click="switchUser()">Switch</button>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<h2>Users</h2>
			<SbaCrudTable v-if="userTable" :my-table="userTable" />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-12">
			<SbaCrudTable v-if="authIpTable" :my-table="authIpTable" />
		</div>
	</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data () {
		return {
			userTable: null,
			authIpTable: null,
			currentAction: '',
			selectedUser: ''
		}
	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},
	methods: {
		tableReady: function (table) {
			if (this.$route.params !== undefined && this.$route.params.action !== undefined) {
				switch(this.$route.params.action) {
					case 'add':
						//add new user
						this.userTable.toggleButton(this.userTable.buttons['C'], {}, false);
						this.currentAction = 'create';
						break;
					case 'update':
						if (this.$route.params.id !== undefined && this.userTable.data != undefined && this.userTable.data.rows != undefined) {
							//get location on name
							var currentRow = this.userTable.data.rows.filter(function(row) { 
								if (row['id'] == this.$route.params.id) {
									return row;
								}
							}, this);
							
							if (currentRow[0] !== undefined) {
								this.userTable.toggleButton(this.userTable.buttons['U'], currentRow[0], false);
								this.currentAction = 'update';
							}
						}
						break;
					case 'profile':
						//update user
						this.userTable.toggleButton(this.userTable.buttons['U'], this.currentUser, false);
						this.currentAction = 'update';
						break;
				}
			}
		},
		afterChange: function (table, data, crudFunction) {
			// Called after SBA Crud Table has performed a change
		},
		sbaCrudTableChange: function(tableName, fieldName, fieldValue, eventType) {
			// Called when SBA Crud Table field change
		},
		switchUser: function(){
			this.req('GET', '/user/doSwitch/' + this.selectedUser).then((response) => {
				if (response.status) {
					window.location.href = "/";
				}	
			});
		}
	},
	mounted () {
		let uploadFileUrl = '/user/uploadImage';
		let deleteFileUrl = '/user/deleteImage';
		let getFileUrl = '/user/getImage';
	
		// set user table
		this.userTable = {
			name: 'users',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'user',
			fields: [
				'organization_id',
				'username',
				'password',
				'name',
				'email',
				'user_role_id',
				'description',
			],
			fieldDetails:{
				'id': {
					hideEdit: true,
					hideList: true,
				},
				'organization_id': {
					label: 'Organization',
				},
				'user_role_id': {
					label: 'User role',
				},
				'supplier_id': {
					label: 'Supplier',
				},
				'password': {
					hideList: true,
					type: 'password',
				},
			},
			autoChangeTo: [
				'organization_id',
				'user_role_id',
				'supplier_id',
			],
		};
		this.authIpTable = {
			name: 'auth_ip_errors',
			pageLimit: 10,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'auth_ip_error',
		};
	}
}
</script>
