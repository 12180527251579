<template>
<div>
	<b-row>
		<b-colxx xxs="12">
			<piaf-breadcrumb :heading="$t('menu.admin')"/>
			<div class="separator mb-5"></div>
		</b-colxx>
	</b-row>
	<b-row v-if="currentUser && currentUser.user_role_id && currentUser.user_role_id > 2">
		<b-colxx xxs="12">
			<Git />
		</b-colxx>
	</b-row>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Git from '@/components/Git'

export default {
	data () {
		return {
		}
	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},
	methods: {

	},
	components: {
		Git,
	},
	created: function () {

	},

}
</script>
