<template>
<div>
	<Git />
</div>
</template>

<script>
import Git from '@/components/Git'
import { mapGetters } from 'vuex'
export default {
	data () {
		return {
		}
	},
	methods: {
	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},
	components:{
		Git,
	},
	mounted () {

	}
}
</script>
