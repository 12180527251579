<template>
<div>
	<div class="row">
		<div class="col-md-12">
			<div class="tiles">
				<label for="file-upload" class="tile tiles-submenu bg-supplier">
					<i class="fas fa-upload"></i> {{ $t('buttons.informer.upload') }}
				</label>
				<input id="file-upload" type="file" @input="uploadXLSFile" accept=".xlsx,.xls" hidden />
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<SbaCrudTable v-if="informerTable" :my-table="informerTable" />
		</div>
	</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data () {
		return {
			informerTable: null,
		}
	},
	methods: {
		uploadXLSFile: function(event) {
			
			let fileInfo = {
				file: event.target.files[0]
			}
			if (fileInfo.file) {
				fileInfo.loading = true;
				let data = new FormData();
				data.append('file', fileInfo.file);
				
				this.req('POST', '/Informer/uploadXLSFile', data).then((response) => {
					if (response.status) {
						fileInfo.loading = false;
						this.informerTable.call('initiate');
					} else {
						this.errorMsg = 'Bestand te groot of corrupt, probeer de inhoud naar een nieuw Excel (xlsx) bestand te kopieren';
						this.$swal('Foutmelding', this.errorMsg, 'warning');
					}
				}, (error) => {
					fileInfo.loading = false;
					if (error.status == 500) {
						this.errorMsg = 'Bestand te groot of corrupt, probeer de inhoud naar een nieuw Excel (xlsx) bestand te kopieren';
						this.$swal('Foutmelding', this.errorMsg, 'warning');
					} else if (error.status > 0) {
						this.errorMsg = error.status + ': ' + error.data;
						this.$swal('Foutmelding', this.errorMsg, 'warning');
					}
				}, function (evt) {
					this.fileInfo.file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
				});
			}
		},
		btnProcess: function(btn, row, blnSumbit) {
			//response
			this.req('POST', '/cron_informer/setProcess/' + row.id).then((response) => {
				this.informerTable.call('initiate');
			});
		}

	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},
	mounted () {
		this.informerTable = {
			name: 'Uploaded files',
			pageLimit: 10,
			tableFold: false,
			controller: 'cron_informer',
			function: 'getAll',
			fields: [
				'trips',
				'customers',
				'boot',
				'running',
				'done',
				'result',
				'created_at',
			],
			fieldDetails: {
				'trips': {
					label: 'Trips',
				},
				'customers': {
					label: 'Customers',
				},
				'boot': {
					label: 'Start',
					type: 'boolean',
					booleanOn: '1',
					booleanOff: '0',
				},
				'running': {
					label: 'Running',
					type: 'boolean',
					booleanOn: '1',
					booleanOff: '0',
				},
				'done': {
					label: 'Done',
					type: 'boolean',
					booleanOn: '1',
					booleanOff: '0',
				},
				'result': {
					label: 'Result',
				},
				'created_at': {
					label: 'Created at',
					sortAsc: false,
				},
			},
			buttons: {
				'P':{
					'id': 'p',
					'name': 'Process',
					'label': 'Process',
					'confirmName': 'Accept',
					'confirmClass': 'success',
					'icon': 'download',
					'action': 'btnProcess',
					'header': false,
					'inline': true,
					'parentScope': true,
					'function': 'setprocess'
				},
				'C':{
					'id': 'c',
					'header': false,
				},
				'U':{
					'id': 'u',
					'header': false,
					'inline': false,
				},
				'R':{
					'id': 'r',
					'header': false,
					'inline': false,
				},
				'D':{
					'id': 'd',
					'header': false,
					'inline': false,
				},
			}
		}

	}
}
</script>
