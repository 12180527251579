<template>
<div>
	<h2 v-if="currentUser.rights.invoice['*'] || currentUser.rights.invoice.c">Create new invoices</h2>
	<div v-if="currentUser.rights.invoice['*'] || currentUser.rights.invoice.c" class="form-group">
		<div class="container-fluid">
			<div class="row">
				<div class="col my-auto input-group-addon">
					<input type="text" readonly class="form-control-plaintext mt-1" value="Month to invoice" />
				</div>
				<div class="col input-group-addon">
					<select v-model="selection.month" class="form-control">
						<option value='' selected>--Select Month--</option>
						<option value='1'>January</option>
						<option value='2'>February</option>
						<option value='3'>March</option>
						<option value='4'>April</option>
						<option value='5'>May</option>
						<option value='6'>June</option>
						<option value='7'>July</option>
						<option value='8'>August</option>
						<option value='9'>September</option>
						<option value='10'>October</option>
						<option value='11'>November</option>
						<option value='12'>December</option>
					</select>
				</div>
				<div class="col input-group-addon">
					<select v-model="selection.year" class="form-control form-inline">
						<option value='' selected>--Select year--</option>
						<option value='2017'>2017</option>
						<option value='2018'>2018</option>
						<option value='2019'>2019</option>
						<option value='2020'>2020</option>
						<option value='2021'>2021</option>
						<option value='2022'>2022</option>
						<option value='2023'>2023</option>
						<option value='2024'>2024</option>
						<option value='2025'>2025</option>
					</select>
				</div>
				<div class="col input-group-addon">
					<select v-model="selection.supplier" class="form-control form-inline">
						<option value='' selected>All Suppliers</option>
						<option :value="supplier.id" v-for="supplier in suppliers" :key="supplier">{{ supplier.iata_codes + ' - ' + supplier.name }}</option>
					</select>
				</div>
				<div class="col input-group-addon">
					<select v-model="selection.department" class="form-control form-inline">
						<option value='' selected>--Select department--</option>
						<template v-for="department in departments" :key="department">   
							<option :value="department.name">{{department.name}}</option>
						</template>
					</select>
				</div>
				<div class="col input-group-addon">
					<button @click="createInvoices();" class="add btn btn-primary" type="button" style="float: right;">Create <i v-show="creatingInvoices" aria-hidden="true" class="fa fa-refresh fa-spin"></i></button>
				</div>
			</div>
		</div>
	</div>

	<h2>Invoices</h2>
	<div v-if="currentUser.rights.invoice['*'] || currentUser.rights.invoice['getinvoice']" class="form-group">
		<div class="container-fluid">
			<div class="row">
				<span class="col input-group-addon">
					<input type="text" readonly class="form-control-plaintext mt-1" value="Filter" />
				</span>
				<span class="col input-group-addon">
					<select v-model="filter.month" @change="onChangeFilter()" class="form-control">
						<option value='' selected>--Select Month--</option>
						<option value='1'>January</option>
						<option value='2'>February</option>
						<option value='3'>March</option>
						<option value='4'>April</option>
						<option value='5'>May</option>
						<option value='6'>June</option>
						<option value='7'>July</option>
						<option value='8'>August</option>
						<option value='9'>September</option>
						<option value='10'>October</option>
						<option value='11'>November</option>
						<option value='12'>December</option>
					</select>
				</span>
				<span class="col input-group-addon">
					<select v-model="filter.year" @change="onChangeFilter()" class="form-control form-inline">
						<option value=''>--Select year--</option>
						<option value='2017'>2017</option>
						<option value='2018'>2018</option>
						<option value='2019'>2019</option>
						<option value='2020'>2020</option>
						<option value='2021'>2021</option>
						<option value='2022'>2022</option>
						<option value='2023'>2023</option>
						<option value='2024'>2024</option>
						<option value='2025'>2025</option>
					</select>
				</span>
			</div>
		</div>
	</div>
	<table class="table table-striped custab">
		<thead>
			<tr>
				<th>#</th>
				<th>Dept.</th>
				<th v-if="currentUser.role.id >= 3">Organization</th>
				<th>Supplier</th>
				<th>Date</th>
				<th>Period</th>
				<th>reference</th>
				<th>Price</th>
				<th>Status</th>
				<th class="text-center">Action</th>
			</tr>
		</thead>
		<tbody v-if="invoices">
			<tr v-for="invoice in filterInvoices('concept', invoices)" :key="invoice">
				<td>{{ invoice.id }}</td>
				<td>{{ invoice.department }}</td>
				<td v-if="currentUser.role.id >= 3">{{ invoice.organization_id ? invoice.organization : invoice.supplier.name }}</td>
				<td>{{ invoice.supplier ? invoice.supplier.name : invoice.supplier.name }}</td>
				<td>{{ invoice.date }}</td>
				<td>{{ $moment(invoice.period).format('MM-YYYY') }}</td>
				<td>{{ invoice.reference }}</td>
				<td>{{ parseFloat(invoice.price).toFixed(2) }} {{ invoice.supplier.currency }}</td>
				<td>{{ invoice.status }}</td>
				<td>
					<button @click="invoiceAction(invoice, 'P')" class="add btn btn-light mr-2 mb-2" type="button">PDF</button>
					<router-link :to="'/page/app/invoice/' + invoice.id">
						<button type="button" class="add btn btn-warning mr-2 mb-2">
							View
						</button>
					</router-link>
					<button v-if="invoice.status_id == 1 && currentUser.role.id >= 3" @click="invoiceAction(invoice, 'AC')" class="add btn btn-primary mr-2 mb-2" type="button">Send for approval</button>
					<button v-if="invoice.type == 'DEBIT' && (invoice.status_id == 2 || invoice.status_id == 3) && (currentUser.role.id == 1 || currentUser.role.id == 4)" @click="invoiceAction(invoice, 'AP')" class="add btn btn-success mr-2 mb-2" type="button">Approve</button>
					
					<button v-if="invoice.type == 'DEBIT' && (invoice.status_id == 2 || invoice.status_id == 3) && (currentUser.role.id >= 3)" @click="invoiceAction(invoice, 'RE')" class="add btn btn-info mr-2 mb-2" type="button">Resend</button>
					<button v-if="invoice.type == 'DEBIT' && invoice.status_id != 3 && (currentUser.role.id == 1 || currentUser.role.id == 4)" @click="invoiceAction(invoice, 'D')" class="add btn btn-danger mr-2 mb-2" type="button">Decline</button>
					<!--<button v-if="invoice.status_id == 3" @click="invoiceAction(invoice, 'VC')" class="add btn btn-warning mr-2" type="button" data-toggle="modal" data-target="#myModal">Comments</button>-->
					<button v-if="invoice.type == 'CREDIT' && (currentUser.rights.invoice['*'] || currentUser.rights.invoice.d)" @click="invoiceAction(invoice, 'APC')" class="add btn btn-success mr-2 mb-2" type="button">Approve Credit</button>
					<button v-if="currentUser.rights.invoice['*'] || currentUser.rights.invoice.d" @click="invoiceAction(invoice, 'C')" class="add btn btn-danger mr-2 mb-2" type="button">Cancel</button>
				</td>
			</tr>
		</tbody>
	</table>

	<h2>Completed</h2>
	<table class="table table-striped custab">
		<thead>
			<tr>
				<th>#</th>
				<th>Dept.</th>
				<th v-if="currentUser.role.id >= 3">Organization</th>
				<th>Supplier</th>
				<th>Date</th>
				<th>Period</th>
				<th>Price</th>
				<th class="text-center">Action</th>
			</tr>
		</thead>
		<tbody v-if="invoices.length">
			<tr v-for="invoice in filterInvoices('completed', invoices)" :key="invoice">
				<td>{{ invoice.id }}</td>
				<td>{{ invoice.department }}</td>
				<td v-if="currentUser.role.id >= 3">{{ invoice.organization_id ? invoice.organization : invoice.supplier.name }}</td>
				<td>{{ invoice.supplier ? invoice.supplier.name : invoice.supplier.name }}</td>
				<td>{{ invoice.date }}</td>
				<td>{{ invoice.period }}</td>
				<td>{{ parseFloat(invoice.price).toFixed(2) }} {{ invoice.supplier.currency }}</td>
				<td>
					<button @click="invoiceAction(invoice, 'P')" class="add btn btn-light mr-2 mb-2" type="button">PDF</button>
					<button v-if="invoice.status_id == 4 && currentUser.role.id >= 3" @click="invoiceAction(invoice, 'REKLC')" class="add btn btn-info mr-2 mb-2" type="button">Resend to {{ invoice.department }}</button>
					<button v-if="invoice.type == 'DEBIT' && invoice.is_credited == 0 && invoice.status_id == 4 && currentUser.role.id >= 3" @click="invoiceAction(invoice, 'createCreditInvoice')" class="add btn btn-warning mr-2 mb-2" type="button">Create Credit Invoice</button>
					<router-link :to="'/page/app/invoice/' + invoice.id">
						<button type="button"  class="add btn btn-warning mr-2 mb-2">
							View
						</button>
					</router-link>
				</td>
			</tr>
		</tbody>
	</table>

</div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
export default {
	data () {
		return {
			selection: {
				month : '',
				year: '',
				supplier: '',
				department: ''
			},
			filter: {},
			selected: {},
			creatingInvoices: false,
			invoices: [],
			suppliers: [],
			loadingInvoices: false,
			departments : null,
		}
	},
	methods: {
		loadInvoices: function(id, year, month) {
			this.loadingInvoices = true;
			let url = '/invoice/getInvoice' + (id !== undefined && id ? '/' + id : '');
			
			if (year !== undefined && month !== undefined) {
				url = '/invoice/getInvoices/' + year + '/' + month; 
			}
			
			//get data from backend
			this.req('GET', url).then((response) => {
				if (response.status) {
					this.invoices = response.data;
				} else {
					this.invoices = [];
				}
				this.loadingInvoices = false;
			});
		},
		createInvoices: function() {
			this.selected = JSON.parse(JSON.stringify(this.selection));
			this.creatingInvoices = true;

			this.req('POST', '/invoice/createInvoice', this.selected).then((response) => {
				this.creatingInvoices = false;
				if (response.status) {
					this.loadInvoices(undefined, this.filter.year, this.filter.month);
				} else if (response.message !== undefined) {
					alert(response.message);
				} else {
					alert('Error occurred');
				}
			});
		},
		loadSuppliers: function() {
			this.req('GET', '/supplier/getAllWithIataCodes').then((response) => {
				if (response.status) {
					this.suppliers = response.data;
				} else {
					this.suppliers = [];
				}
			});
		},
		filterInvoices: function(type, invoices) {
			let filteredInvoices = [];
			if (type == 'completed'){
				invoices.forEach((invoice) => {
					if (invoice.status_id >= 4){
						filteredInvoices.push(invoice);
					}
				});
			} else {
				invoices.forEach((invoice) => {
					if (invoice.status_id < 4){
						filteredInvoices.push(invoice);
					}
				});
			}
			return filteredInvoices;
		},
		onChangeFilter: function() {
			this.req('GET', '/invoice/getInvoices/' + this.filter.year + '/' + this.filter.month).then((response) => {
				if (response.status) {
					this.invoices = response.data;
				} else {
					this.invoices = [];
				}
			});
		},
		invoiceAction: function(invoice, action) {
			switch (action) {
				case 'P':
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							window.open('/invoice/createPDF/' + invoice.id, '_blank');
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'AC': // accept draft
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							this.req('POST', '/invoice/update/' + invoice.id, {'status_id': 2 }).then((response) => {
								if (response.status) {
									this.updateInvoice(invoice);
								}else{
									let errorMessage = 'Invoice accept ERROR';
									
									this.$swal('Error', errorMessage, 'error');
								}
							});
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'AP': // approve invoice
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							this.req('POST', '/invoice/approve/' + invoice.id).then((response) => {
								if (response.status) {
									this.updateInvoice(invoice);
								}else{
									let errorMessage = 'Invoice approve ERROR, please contact us';
									this.$swal('Error', errorMessage, 'error');
								}
							});
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'APC': // approve credit invoice
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							this.req('POST', '/invoice/approveCredit/' + invoice.id).then((response) => {
								if (response.status) {
									this.updateInvoice(invoice);
								}else{
									let errorMessage = 'Invoice approve ERROR';
									
									this.$swal('Error', errorMessage, 'error');
								}
							});
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'RE': // resend invoice
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							this.req('POST', '/invoice/resendInvoice/' + invoice.id).then((response) => {
								if (response.status) {
									this.$swal('Success', 'Invoice resend success', 'success');
									this.updateInvoice(invoice);
								}else{
									let errorMessage = 'Invoice resend ERROR';
									
									this.$swal('Error', errorMessage, 'error');
								}
							});
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'REKLC': // resend invoice
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							this.req('POST', '/invoice/resendFinalInvoice/' + invoice.id).then((response) => {
								if (response.status) {
									this.$swal('Success', 'Invoice resend success', 'success');
									this.updateInvoice(invoice);
								}else{
									let errorMessage = 'Invoice resend ERROR';
									
									this.$swal('Error', errorMessage, 'error');
								}
							});
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'createCreditInvoice': // resend invoice
					this.req('POST', '/invoice/createPDF/' + invoice.id, {'data': true, 'address': true}).then((response) => {
						if (response.status) {
							this.req('POST', '/invoice/createCreditInvoice/' + invoice.id).then((response) => {
								if (response.status) {
									if (this.filter) {
										this.loadInvoices(undefined, this.filter.year, this.filter.month);
									} else {
										this.$router.push({ name: 'invoices' });
									}
									this.$swal('Success','Credit Invoice Created', 'success');
								} else if (response.message) {
									this.$swal('Error', response.message, 'error');
								} else {
									this.$swal('Error', 'Credit Invoice NOT Created', 'error');
								}
							});
						} else {
							let errorMessage = 'Something went wrong';
							switch(response.message) {
								case 'messages.get_invoice.failed':
									errorMessage = 'Invoice could not be fetched';
								break;
								case 'messages.invoice_address.invalid':
									errorMessage = 'Invoice address missing';
								break;
							}
							if (this.currentUser.user_role_id <= 2) {
								errorMessage += ', please contact us'
							}

							this.$swal('Error', errorMessage, 'error');
						}
					});
					break;
				case 'D': // decline draft / invoice
					var comment = prompt("What is the reason for declining the invoice?", "");

					if (comment != null && comment != "") {
						this.req('POST', '/invoice_comment/insert', { 'invoice_id': invoice.id, 'comment': comment }).then((response) => {
							if (response.status) {
								this.req('POST', '/invoice/decline/' + invoice.id, {}).then((response) => {
									if (response.status) {
										this.updateInvoice(invoice);
									}
								});
							}
						});
					}
					break;
				case 'VC': // view comments
					this.req('GET', '/invoice_comment/getComment/' + invoice.id).then((response) => {
						if (response.status) {
							this.modal.comments = response.data;
						}
					});
					break;
				case 'C': // cancel invoice
					var c = confirm('Are you sure to delete invoice #' + invoice.id);
					if (!c) { return; }
					this.req('GET', '/invoice/delete/' + invoice.id).then((response) => {
						if (response.status) {
							let index = this.invoices.indexOf(invoice);
							if (index !== -1) {
								this.invoices.splice(index, 1);
							}
							if (this.invoices.length == 0) {
								this.$router.push({ name: 'invoices' });
							}
						}
					});
					break;
			}
		},
		updateInvoice: function(invoice) {
			this.req('GET', '/invoice/getInvoice/' + invoice.id).then((response) => {
				if (response.status) {
					let index = this.invoices.indexOf(invoice);
					if (index !== -1) {
						this.invoices[index] = response.data[0];
					}
				}
			});
		},
		fetchDepartments: function() {
			this.req('GET', '/department/get/').then((response) => {
				if(response.status){
					this.departments = response.data;
				}
			});
		}
	},
	created () {
		this.$moment = moment;
	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},
	mounted () {
		this.fetchDepartments();
		this.selection.year = this.$moment(new Date).subtract(1, 'months').format('YYYY');
		this.selection.month = this.$moment(new Date).subtract(1, 'months').format('M');
		
		this.loadSuppliers();
		this.filter.year = this.$moment(new Date).subtract(1, 'months').format('YYYY');
		this.filter.month = this.$moment(new Date).subtract(1, 'months').format('M');
		this.loadInvoices(undefined, this.filter.year, this.filter.month);

		if (this.currentUser && this.currentUser.organization_id && this.currentUser.organization_id == 150) { //check for transavia
			this.selection.department = 'Transavia';
		} else {
			this.selection.department = 'KLC';
		}
	}
}
</script>
