<template>
<div>
	<div v-show="!toLogbook && !scheduleItem.show	">
		<div class="row" v-if="currentUser && currentUser.rights && currentUser.rights.schedule && currentUser.rights.schedule.uploadpdffile">
			<div class="col-lg-12 col-md-12">
				<div class="tiles">
					<label for="file-upload" class="tile tiles-submenu bg-supplier">
						<i class="fas fa-upload"></i> {{ $t('buttons.schedule.upload') }}
					</label>
					<input id="file-upload" type="file" @input="uploadFile" hidden/>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<h2>{{ $t('schedule.pickups') }}</h2>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-12 col-md-12">
				<SbaCrudTable v-if="scheduleTable" :my-table="scheduleTable" />
			</div>
			<p>&nbsp;</p>
		</div>
	</div>
	<div v-show="scheduleItem.show">
		<div class="row">
			<div class="col text-right">
				<button type="button" class="btn btn-warning" @click="backToSchedules();">Back to schedules</button>
			</div>
		</div>
		<h2>{{ $t('schedule.surcharges.title') }}</h2>
		<h3>{{ $t('schedule.surcharges.schedule') }}</h3>
		<table class="table table-striped" v-if="scheduleItem.data">
			<tbody>
				<tr>
					<th>#</th>
					<th>Date</th>
					<th>IATA</th>
					<th>Flightnr</th>
					<th>Direction</th>
				</tr>
				<tr>
					<td>{{ scheduleItem.data.id }}</td>
					<td>{{ $moment(scheduleItem.data.date).format("DD-MM-YYYY") }}</td>
					<td>{{ scheduleItem.data.iata_code }}</td>
					<td>{{ scheduleItem.data.flightnr }}</td>
					<td>{{ scheduleItem.data.direction }}</td>
				</tr>
				<tr>
					<th colspan="2">Hotel</th>
					<th>Pickup</th>
					<th>Worktime</th>
					<th>Passengers</th>
				</tr>
				<tr>
					<td colspan="2">{{ scheduleItem.data.hotel }}</td>
					<td>{{ $moment(scheduleItem.data.pickuptime).format('HH:mm') }}</td>
					<td>{{ $moment(scheduleItem.data.worktime).format('HH:mm') }}</td>
					<td>{{ scheduleItem.data.passengers }}</td>
				</tr>
			</tbody>
		</table>
		<h3>
		{{ $t('schedule.surcharges.view') }} <i @click="addSurcharge();" v-if="currentUser.role.id > 2" aria-hidden="true" class="fa fa-plus"></i>
		</h3>
		<table class="table table-striped" v-if="scheduleItem.data && scheduleItem.data.fees && scheduleItem.data.fees.length">
			<thead>
				<tr>
					<th>#</th>
					<th>Name</th>
					<th>Amount</th>
					<th>VAT percentage</th>
					<th>Extra Trip</th>
					<th>Reason</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(fee, index) in scheduleItem.data.fees" :key="fee">
					<td>{{ index+1 }}</td>
					<td>{{ fee.name }}</td>
					<td>{{ fee.amount }}</td>
					<td>{{ fee.vat_percentage }}%</td>
					<td>{{ fee.extra_trip }}</td>
					<td>{{ fee.reason }}</td>
					<td><i @click="deleteSurcharge(fee);" aria-hidden="true" class="fa fa-trash"></i></td>
				</tr>
				<tr>
					<td>&nbsp;</td>
					<td>Fee Total</td>
					<td>{{ (getSurchargeTotal()) }}</td>
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<td>&nbsp;</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div v-show="toLogbook">
		<h2>{{ $t('schedule.processing') }}</h2>
		<div class="row">
			<div class="col-md-4 btn-processLogbook">
				<button class="btn btn-warning" @click="cancelLogbook()">{{ $t('button.cancel') }}</button>
			</div>
			<div class="col-md-4 btn-processLogbook">
				<b>{{ toLogbookIndex+1 }}</b> {{ $t('general.of') }} <b>{{  toLogbookData.length }}</b>
			</div>
			<div class="col-md-4 btn-processLogbook">
				<button class="btn btn-warning" :disabled="!nextLogbookIndexAvailable()" @click="nextLogbookIndex()">{{ $t('buttons.schedule.cancel') }}</button>
				<span>&nbsp;</span>
				<button class="btn btn-primary" :disabled="!processAvailable() || processTripLoading" @click="processCurrent()">{{ $t('buttons.schedule.process') }}</button>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 col-lg-12">
				<table class="table table-striped" v-if="toLogbookData && toLogbookData[toLogbookIndex]">
					<tbody>
						<tr>
							<th>{{ $t('schedule.date') }} *</th>
							<td>{{ $moment(toLogbookData[toLogbookIndex].date).format("YYYY-MM-DD") }}</td>
						</tr>
						<tr>
							<th>{{ $t('schedule.direction') }} *</th>
							<td>{{ toLogbookData[toLogbookIndex].direction }}</td>
						</tr>
						<tr>
							<th>{{ $t('schedule.department') }}</th>
							<td>
								<select name="department" id="department" v-model="toLogbookData[toLogbookIndex].department">
									<template v-for="department in departments" :key="department">   
										<option :value="department.name">{{department.name}}</option>
									</template>
								</select>
							</td>
						</tr>
						<tr>
							<th>{{ $t('schedule.flightnr') }} *</th>
							<td><input type="text" class="form-control" v-model="toLogbookData[toLogbookIndex].flightnr" /></td>
						</tr>
						<tr>
							<th>{{ $t('schedule.iata') }} *</th>
							<td>{{ toLogbookData[toLogbookIndex].iata_code }}</td>
						</tr>
						<tr>
							<th>{{ $t('schedule.hotel') }} *</th>
							<td><textarea rows="4" class="form-control" v-model="toLogbookData[toLogbookIndex].hotel" ></textarea></td>
						</tr>
						<tr>
							<th>{{ $t('schedule.passengers') }} *</th>
							<td><input type="number" class="form-control" v-model="toLogbookData[toLogbookIndex].passengers" /></td>
						</tr>
						<tr>
							<th>{{ $t('schedule.supplier') }} *</th>

							<td>
								<select class="form-control" v-model="toLogbookData[toLogbookIndex].supplier_id" >
									<option v-for="supplier in toLogbookData[toLogbookIndex].suppliers" :key="supplier" :value="supplier.id">
										{{supplier.name}}
									</option>
								</select>
							</td>
						</tr>
						<tr>
							<th>{{ $t('schedule.car-type') }} *</th>
							<td>
								<select class="form-control" v-model="toLogbookData[toLogbookIndex].car_id">
									<option v-for="car in cars" :key="car" :value="car.id">
										{{car.name + ' (' + car.maxnumberofpeople + ' persons)'}}
									</option>
								</select>
							</td>
						</tr>
						<tr>
							<th>{{ $t('schedule.custom-price') }}</th>
							<td><input type="checkbox" value="1" v-model="toLogbookData[toLogbookIndex].customPrice" /></td>
						</tr>
						<tr v-show="toLogbookData[toLogbookIndex].customPrice">
							<th>{{ $t('schedule.price') }}</th>
							<td><input type="number" v-model="toLogbookData[toLogbookIndex].price" /></td>
						</tr>
						<tr v-show="!toLogbookData[toLogbookIndex].customPrice">
							<th>{{ $t('schedule.nightfare') }}</th>
							<td><input type="checkbox" v-model="toLogbookData[toLogbookIndex].nightfare" /></td>
						</tr>
						<tr>
							<th>{{ $t('schedule.reason') }}</th>
							<td>
								<textarea class="form-control" v-model="toLogbookData[toLogbookIndex].reason" rows="4" cols="50"></textarea>
							</td>
						</tr>
						<tr v-if="toLogbookData[toLogbookIndex].automaticSurcharge">
							<th>{{ $t('schedule.surcharge') }}</th>
							<td>
								<div class="row align-items-end">
									<div class="col-md-4">
										<label for="surchargeName">{{ $t('schedule.surcharge_name') }}</label>
										<input id="surchargeName" class="form-control"  type="text" v-model="toLogbookData[toLogbookIndex].automaticSurcharge.name" />
									</div>
									<div class="col-md-3">
										<label for="surchargeAmount">{{ $t('schedule.surcharge_amount') }}</label>
										<input id="surchargeAmount" class="form-control"  type="number" v-model="toLogbookData[toLogbookIndex].automaticSurcharge.amount" />
									</div>
									<div class="col-md-3">
										<label for="surchargeVat">{{ $t('schedule.surcharge_vat') }}</label>
										<input id="surchargeVat" class="form-control"  type="number" v-model="toLogbookData[toLogbookIndex].automaticSurcharge.vat" />
									</div>
									<div class="col-md-2">
										<button class="form-control btn btn-danger"  type="button" @click="removeSurchargeRow(toLogbookIndex)">{{ $t('schedule.delete') }}</button>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<!-- Modal Surcharges -->
	<div id="myModal" class="modal show" tabindex="-1" role="dialog" v-if="modal && modal.schedule">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Schedule - add surcharge</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="modal.schedule = false;">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<form id="surchargeForm" @submit.prevent="addSurcharge(modal.schedule)">
						<div class="form-group">
							<label for="department">Department</label>
							<input id="department" class="form-control" v-model="surcharge.department" type="text" :disabled="true" required />
						</div>
						<div class="form-group">
							<label for="name">Name</label>
							<input id="name" class="form-control" v-model="surcharge.name" type="text" required />
						</div>
						<div class="form-group">
							<label for="reason">Reason</label>
							<input id="reason" class="form-control" v-model="surcharge.reason" type="text" />
						</div>
						<div class="form-group">
							<label for="extra_trip">Extra Trip</label>
							<select id="extra_trip" class="form-control" v-model="surcharge.extra_trip">
								<option value="No">No</option>
								<option value="Single">Single</option>
								<option value="Double">Double</option>
								<option value="SingleNightfare">Single (Nightfare)</option>
								<option value="DoubleNightfare">Double (Nightfare)</option>
							</select>
						</div>
						<div class="form-group" v-show="surcharge.extra_trip == 'No'">
							<label for="amount">Amount</label>
							<input id="amount" class="form-control" v-model="surcharge.amount" type="number" step="any" />
						</div>
						<div class="form-group" v-show="surcharge.extra_trip == 'No'">
							<label for="vat_percentage">VAT percentage</label>
							<input id="vat_percentage" class="form-control" v-model="surcharge.vat_percentage" type="number" step="any" />
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<span>{{ modal.schedule.surcharge && modal.schedule.surcharge.status ? 'Surcharge added successfully to the schedule!' : '' }}</span>
					<button type="submit" class="btn btn-success" :disabled="modal.schedule.surcharge && modal.schedule.surcharge.status" form="surchargeForm">Add</button>
					<button type="button" class="btn btn-default" :disabled="modal.schedule.surcharge && modal.schedule.surcharge.status" @click="modal.schedule = false;">Close</button>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
	data () {
		return {
			scheduleTable: null,
			cars: [],
			toLogbookData: [],
			toLogbook: false,
			toLogbookTable: '',
			toLogbookIndex: 0,
			suppliersByIataCode: [],
			organizations: [],
			scheduleItem: {},
			departments: {},
			modal: {
				schedule: false,
			},
			surcharge: {},
			processTripLoading: false,
		}
	},
	methods: {
		uploadFile: function(event) {
			let fileInfo = {
				file: event.target.files[0],
			}

			if (fileInfo.file) {
				fileInfo.loading = true;

				var formData = new FormData();
				formData.append('file', fileInfo.file)

				this.req('POST', '/schedule/uploadPDFFile', formData).then((response) => {
					if (response.status){
						this.scheduleTable.call('initiate');
						this.$swal('Success', "Upload successfull", 'success');
					} else {
						this.$swal('Error', "Upload was not successfull, because " + (response.data && response.data.data && response.data.data[0] ? response.data.data[0] : (response.data.message ? response.data.message : response.message)), 'error');
					}
				}, () => {
					this.$swal('Error', "Upload unsuccessfull", 'error');
				});
			}
		},
		nextLogbookIndexAvailable: function(){
			if((this.toLogbookIndex + 1) < this.toLogbookData.length){
				return true;
			}else{
				return false;
			}
		},
		removeSurchargeRow: function(index) {
			this.toLogbookData[index].automaticSurcharge = undefined;
		},
		cancelLogbook: function () {
			this.toLogbook = false;
			this.scheduleTable.call('initiate');
		},
		nextLogbookIndex: function(){
			if((this.toLogbookIndex + 1) < this.toLogbookData.length){
				//process current
				this.toLogbookIndex++;
			}
		},
		btnProcess: function (btn, row, blnSubmit) {
			let selectedItems = [];

			//loop selected items to set supplier id
			JSON.parse(JSON.stringify(this.scheduleTable.selectedItems)).forEach((selectedItem) => {
				if (selectedItem.original_supplier_id != undefined) {
					selectedItem.supplier_id = selectedItem.original_supplier_id;
				}

				selectedItems.push(selectedItem);
			});

			this.toLogbookData = JSON.parse(JSON.stringify(selectedItems));
			this.processLogbook('scheduleTable');
			
		},
		processLogbook: function(tableName) {
			this.loadDataToScope('supplier_destination', 'suppliersDes', 'getForLogbook');
			this.loadDataToScope('supplier', 'suppliers');
			this.loadDataToScope('car', 'cars');
			this.loadDataToScope('surcharge', 'automaticSurcharge', 'getSurcharge');
			this.toLogbook = true;
			this.toLogbookTable = tableName;
			this.toLogbookIndex = 0;
		},
		loadDataToScope: function(controller, variable, strFunction){
			//if function not defined
			if(strFunction === undefined){
				strFunction = 'getAll';
			}

			// get the situationTypes
			this.req('GET', '/' + controller + '/' + strFunction).then((response) => {
				if (response.status) {
					this[variable] = response.data;
					this.convertSupplierDes();
					this.addAutomaticSurcharges();
				}
			});
		},
		addAutomaticSurcharges: function(){
			if (this.automaticSurcharge != undefined && Array.isArray(this.automaticSurcharge)){
				//process surcharge
				let surchargeBySupplier = {};

				this.toLogbookData.forEach((entry) => {
					surchargeBySupplier = {};
					surchargeBySupplier = this.automaticSurcharge.find(surcharge => {
						return (surcharge['department'] == entry['department'] && surcharge['supplier_id'] == entry['supplier_id'] && surcharge['iata'] == entry['iata_code'])
					});
					entry.automaticSurcharge = surchargeBySupplier;
				});
			}
		},
		convertSupplierDes: function(){
			//all gets done
			if(this.suppliersDes === undefined || this.suppliers === undefined || this.cars === undefined){
				return;
			}

			//process supplier destination data
			this.suppliersByIataCode = [];
			this.suppliersDes.forEach((supplierDestination) => {
				if(supplierDestination.destination !== undefined && supplierDestination.destination.iata !== undefined && supplierDestination.supplier !== undefined && supplierDestination.supplier.name !== undefined){
					//parameters
					let iata_code = supplierDestination.destination.iata;
					let supplier_id = supplierDestination.supplier_id;
					let supplier_name = supplierDestination.supplier.name;

					//if array key doesnt exist
					if(this.suppliersByIataCode[iata_code] === undefined){
						this.suppliersByIataCode[iata_code] = [];
					}
					//create new supplier
					let objSupplier = {
						'id': supplier_id,
						'name': supplier_name,
					};
					this.suppliersByIataCode[iata_code].push(objSupplier);
				}
			});

			//process logbook data
			this.toLogbookData.forEach((logbookEntry) => {
				//nightfare check
				logbookEntry.nightfare = false;

				//custom price check
				if (logbookEntry.custom_price !== undefined && !isNaN(logbookEntry.custom_price) && logbookEntry.custom_price > 0) {
					logbookEntry.customPrice = true;
					logbookEntry.price = logbookEntry.custom_price;
				}
				
				let tmpTime = parseInt(this.$moment(logbookEntry.pickuptime).format('H'))
				if(tmpTime >= 22 || tmpTime <= 6){
					logbookEntry.nightfare = true;
				}
				//process passengers
				if(logbookEntry.passengers){
					logbookEntry.passengers = parseInt(logbookEntry.passengers);
				}

				//car check
				let tmpFilterCars = this.cars;
				let carFound = false;
				let car = {};
				tmpFilterCars = tmpFilterCars.find((car) => {
					if(!carFound && car.maxnumberofpeople >= logbookEntry.passengers){
						carFound = true;
						return true;
					}else{
						return false;
					}
				});
				//check if car found
				if(tmpFilterCars && tmpFilterCars[0] !== undefined){
					logbookEntry.car_id = tmpFilterCars[0].id;
				} else if (tmpFilterCars && tmpFilterCars['id'] !== undefined) {
					logbookEntry.car_id = tmpFilterCars['id'];
				}

				//supplier match
				if(this.suppliersByIataCode[logbookEntry.iata_code] !== undefined){
					//if only one
					logbookEntry.suppliers = this.suppliersByIataCode[logbookEntry.iata_code];

					if(this.suppliersByIataCode[logbookEntry.iata_code].length == 1){
						logbookEntry.supplier_id = this.suppliersByIataCode[logbookEntry.iata_code][0].id;
					}
				}else{
					logbookEntry.suppliers = this.suppliers;
				}
			});
		},
		processAvailable: function() {
			let blnReturn = true;
			//wanted rows
			let arrDataRequired = [
				{
					'name': 'id',
					'type': 'id',

				},{
					'name': 'car_id',
					'type': 'int',

				},{
					'name': 'supplier_id',
					'type': 'int',

				},{
					'name': 'direction',
					'type': 'string',

				},{
					'name': 'flightnr',
					'type': 'string',

				},{
					'name': 'iata_code',
					'type': 'string',

				},{
					'name': 'hotel',
					'type': 'string',

				},{
					'name': 'date',
					'type': 'date',

				},{
					'name': 'worktime',
					'type': 'time',

				},{
					'name': 'pickuptime',
					'type': 'time',

				},{
					'name': 'passengers',
					'type': 'int',

				},{
					'name': 'supplier_id',
					'type': 'id',
				},
			];
			let checkData = this.toLogbookData[this.toLogbookIndex];
			
			//loop through data and deleting unwanted rows
			if(checkData === undefined){
				let blnReturn =  false;
			} else {
				arrDataRequired.forEach((requiredField) =>{
					switch (requiredField.type) {
						case 'int':
							if(parseInt(checkData[requiredField.name]) !== undefined){
								if(parseInt(checkData[requiredField.name]) <= 0 || isNaN(checkData[requiredField.name])){
									blnReturn = false;
								}
							}else{
								blnReturn = false;
							}
							break;
						case 'string':
							if(checkData[requiredField.name] === undefined || checkData[requiredField.name] == "" || !checkData[requiredField.name]){
								blnReturn = false;
							}
							break;
						case 'date':
							if(checkData[requiredField.name] instanceof Date){
								checkData[requiredField.name] = this[this.toLogbookTable].convertDateObjToJS(checkData[requiredField.name], true, requiredField.type);
							}
							if(checkData[requiredField.name] === undefined){
								blnReturn = false;
							}
							break;
						case 'id':
							if(checkData !== undefined && checkData[requiredField.name] !== undefined){
								if(parseInt(checkData[requiredField.name]) <= 0 || isNaN(checkData[requiredField.name])){
									blnReturn = false;
								}
							}else{
								blnReturn = false;
							}
							break;
						case 'time':
							if(checkData[requiredField.name] instanceof Date){
								checkData[requiredField.name] = this.$moment(checkData[requiredField.name]).format("HH:mm");
							}
							if(checkData[requiredField.name] === undefined){
								blnReturn = false;
							}
							break;
					}
				});
			}
			if(!blnReturn){
				//console.error("ERROR RETRIEVING DATA \n PLEASE FILL IN ALL REQUIRED FIELDS");
			}

			return blnReturn;

		},
		processCurrent: function(){
			//wanted rows
			let arrDataRequired = [
				{
					'name': 'id',
					'type': 'id',

				},{
					'name': 'car_id',
					'type': 'int',

				},{
					'name': 'supplier_id',
					'type': 'int',

				},{
					'name': 'direction',
					'type': 'string',

				},{
					'name': 'flightnr',
					'type': 'string',

				},{
					'name': 'iata_code',
					'type': 'string',

				},{
					'name': 'hotel',
					'type': 'string',

				},{
					'name': 'date',
					'type': 'date',

				},{
					'name': 'worktime',
					'type': 'date',

				},{
					'name': 'pickuptime',
					'type': 'date',

				},{
					'name': 'passengers',
					'type': 'int',

				},{
					'name': 'reason',
					'type': 'string',

				},{
					'name': 'nightfare',
					'type': 'boolean',

				},{
					'name': 'customPrice',
					'type': 'boolean',

				},{
					'name': 'department',
					'type': 'string',

				},{
					'name': 'price',
					'type': 'string',

				},{
					'name': 'supplier',
					'type': 'id',
				},{
					'name': 'fees',
					'type': 'array',
				},
			];

			//copy original array
			let filterData = JSON.parse(JSON.stringify(this.toLogbookData[this.toLogbookIndex]));
			let insertData = [];

			arrDataRequired.forEach((requiredField) =>{
				if(filterData[requiredField.name] !== undefined){
					insertData[requiredField.name] = filterData[requiredField.name];
				}
			});

			this.uploadLogbookData(insertData);
		},
		uploadLogbookData: function(data) {
			//set loading
			this.processTripLoading = true;

			let insertData = {
				'car_id': parseInt(data.car_id),
				'supplier_id': parseInt(data.supplier_id),
				'direction': data.direction,
				'flightnr': data.flightnr,
				'iata_code': data.iata_code,
				'hotel': data.hotel,
				'date': this.$moment(data.date).format('YYYY-MM-DD'),
				'worktime': this.$moment(data.worktime).format('HH:mm:ss'),
				'pickuptime': this.$moment(data.pickuptime).format('HH:mm:ss'),
				'nightfare': (data.nightfare ? "Y" : "N"),
				'numberofpeople': parseInt(data.passengers),
				'from_id': 0,
				'to_id': 0,
				'comments': data.reason,
				'fees': data.fees,
				'department': data.department,
			};

			//check custom price
			if(data.customPrice == '1') {
				if (data.price) {
					insertData.price = parseFloat(data.price);
				}
			}

			
			this.req('POST', '/trip/insert', insertData).then((response) => {
				if (response.status) {

					//delete fees on success
					if(this.toLogbookData[this.toLogbookIndex].fees){
						this.deleteFees(this.toLogbookData[this.toLogbookIndex].fees);
					}
					
					//set logbook id
					let logBookEntryId = this.toLogbookData[this.toLogbookIndex].id;

					if (this.toLogbookData[this.toLogbookIndex].automaticSurcharge){
						let insertFees = {
							'supplier_id': parseInt(data.supplier_id),
							'trip_id': parseInt(response.data.id),
							'department': data.department,
							'name': this.toLogbookData[this.toLogbookIndex].automaticSurcharge.name,
							'extra_trip': 'No',
							'amount': this.toLogbookData[this.toLogbookIndex].automaticSurcharge.amount,
							'vat_percentage': this.toLogbookData[this.toLogbookIndex].automaticSurcharge.vat,
							'is_invoiced': 0,
						}
						this.req('POST', '/fee/insert', insertFees).then();
					}


					//success so delete
					let btn = {
						'action': 'delete',
					}
					let row = {
						'id': logBookEntryId,
					}

					this[this.toLogbookTable].toggleButton(btn, row, true);

					//next item to process
					if((this.toLogbookIndex + 1) < this.toLogbookData.length){
						this.nextLogbookIndex();
					}else{
						this.toLogbook = false;
						this[this.toLogbookTable].call('initiate');
					}
				}else{
					console.error(response);
				}
				//unset loading
				this.processTripLoading = false;
			}, (error) => {
				//unset loading
				this.processTripLoading = false;
			});
		},

		btnSendInfo: function(btn, row, blnSubmit){
			let arrBtnSendInfo = JSON.parse(JSON.stringify(this.scheduleTable.selectedItems));
			let arrItems = [];
			arrBtnSendInfo.forEach((infoItem) => {
				arrItems.push(parseInt(infoItem.id));
			});
			this.scheduleTable.readOnly = true;
			//send mails
			let data = {
				"items": arrItems,
			};
			this.req('POST', '/schedule/sendInfoMail', data).then((response) => {
				this.scheduleTable.readOnly = false;
				
				if (response.status) {
					// set supplier info sent via backend
					this.scheduleTable.call('initiate');
				}
			});
		},
		
		deleteFees: function(fees){
			fees.forEach((fee) => {
				this.req('GET', '/schedule_fee/delete/' + fee.id).then((response) => {
					if (!response.status) {
						console.error(response);
					}
				});
			});
		},
	
		deleteFromSchedule: function(id){
			this.req('POST', '/schedule/deleteOnId', id).then(function (response) {
				if (!response.status) {
					console.error(response.data);
				}
			});
		},
		//----------------[surcharges]------------
		backToSchedules: function(){
			this.scheduleItem = {};
		},
		
		//add surcharges
		btnAddSurcharges: function(btn, row, blnSubmit){
			this.scheduleItem.data = row;
			if (this.scheduleItem.data.fees === undefined || !Array.isArray(this.scheduleItem.data.fees)) {
				this.scheduleItem.data.fees = [];
			}
			
			this.scheduleItem.show = true;
		},
		
		getSurchargeTotal: function(){
			let total = 0.00;
			this.scheduleItem.data.fees.forEach((fee) => {
				total += parseFloat(fee.amount);
			});
			
			return total;
		},
		
		addSurcharge: function() {
			if (this.modal === undefined || this.modal === null) { this.modal = {}; }

			if (this.modal.schedule) {
				this.surcharge.schedule_id = this.scheduleItem.data.id;
				
				//is there an original supplier id?
				if(this.scheduleItem.data.original_supplier_id){
					this.surcharge.supplier_id = this.scheduleItem.data.original_supplier_id;
				}
				
				//insert schedule
				this.req('POST', '/schedule_fee/insert', this.surcharge).then((response) => {
					this.modal.schedule.surcharge = {
						status: response.status,
					}
					if (response.status) {
						this.scheduleItem.data.fees.push(response.data);
						
						setTimeout(() => {
							delete this.modal.schedule.surcharge;
							this.modal.schedule = false;
						}, 2000);
					}
				});
			} else {
				this.modal.schedule = {};
				this.surcharge = {
					'department': this.scheduleItem.data.department,
					'extra_trip': 'No',
				};
			}
		},
		
		deleteSurcharge: function(fee) {
			this.req('GET', '/schedule_fee/delete/' + fee.id).then((response) => {
				if(response.status){
					let index = this.scheduleItem.data.fees.indexOf(fee);
					this.scheduleItem.data.fees.splice(index, 1);
				}
			});
		},

		fetchDepartments: function() {
			this.req('GET', '/department/get/').then((response) => {
				if(response.status){
					this.departments = response.data;
				}
			});
		}
		
		//----------------[end surcharges]------------


	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},
	created () {
		this.$moment = moment;
	},
	mounted () {
		this.fetchDepartments();
		this.scheduleTable = {
			name: this.$t('table.schedule.title'),
			pageLimit: 10,
			selectable: true,
			showRowCount: true,
			showStatistics: false,
			tableFold: false,
			controller: 'schedule',
			function: 'getPast',
			listUndefinedFields: true,
			allowDeleteMany: true,
			fields: [
				'supplier_id',
				'organization_id',
				'department',
				'date',
				'iata_code',
				'flightnr',
				'hotel',
				'direction',
				'operation_email_sent',
				'pickuptime',
				'worktime',
				'passengers',
				'update_check',
				'data',
				'checked',
			],
			fieldDetails: {
				'date': {
					sortAsc: true,
					required: true,
				},
				iata_code:{
					required: true,
				},
				direction: {
					type: 'enum',
					required: true,
					options: [
						{
							id: 'Arrival',
							name: 'Arrival',
						},{
							id: 'Departure',
							name: 'Departure',
						},
					],
				},
				'data': {
					'hideList': true,
					'hideEdit': true,
				},
				'checked': {
					'hideList': true,
					'hideEdit': true,
				},
				'pickuptime': {
					required: true,
				},
				'worktime': {
					required: true,
					'hideList': true,
				},
				'passengers': {
					type: 'number',
					required: true,
				},
				'operation_email_sent': {
					type: 'enum',
					label: 'Operations Email',
					required: false,
					options: [
						{
							id: 'Y',
							name: 'Y',
						},{
							id: 'N',
							name: 'N',
						},
					],
				},
				'update_check': {
					type: 'enum',
					label: 'Update Check',
					required: false,
					options: [
						{
							id: '1',
							name: '1',
						},{
							id: '0',
							name: '0',
						},
					],
				},
				'supplier_id': {
					label: 'Supplier',
					'hideList': false,
					'hideEdit': false,
				},
				'organization_id': {
					label: 'Organization',
					'hideList': false,
					'hideEdit': this.currentUser.organization_id > 2,
				},'department': {
					label: 'Department',
					'hideList': false,
					'hideEdit': false,
				},
				'car_id' : {
				label: 'Car'
				} 
			},
			style: {
				row: {
					'bg-danger text-white': {
						'update_check': '==1',
					},
				},
			},
			autoChangeTo:[
				'supplier_id', 'car_id', 'organization_id',
			],
			buttons: {
				'Y':{
					'id': 'y',
					'name': this.$t('table.schedule.process'),
					'label': this.$t('table.schedule.process'),
					'tooltip': this.$t('table.schedule.process'),
					'confirmName': 'Accept',
					'confirmClass': 'success',
					'icon': 'eye-slash',
					'action': 'btnProcess',
					'header': true,
					'inline': false,
					'function': 'insert',
					'parentScope': true,
				},
				'E':{
					'id': 'e',
					'name': this.$t('table.schedule.send'),
					'confirmName': 'Accept',
					'confirmClass': 'success',
					'icon': 'envelope',
					'action': 'btnSendInfo',
					'header': true,
					'inline': false,
					'function': 'insert',
					'parentScope': true,
				},
				'S':{
					'id': 's',
					'name': this.$t('table.schedule.surcharge'),
					'confirmName': 'Add',
					'confirmClass': 'success',
					'icon': 'dollar-sign',
					'action': 'btnAddSurcharges',
					'header': false,
					'inline': true,
					'function': 'insert',
					'parentScope': true,
				},
			},
		};
	}
}
</script>
